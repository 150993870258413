import * as React from 'react';
import { Pane } from '@independent-software/typeui/controls/Pane';
import { Header } from '@independent-software/typeui/controls/Header';
import { Table } from '@independent-software/typeui/controls/Table';
import { IStation } from '../types/IStation';

interface IProps {
  station: IStation;
  onClose: () => void;
}

class StationPane extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <Pane padded open={!!p.station} onClose={p.onClose}>
        <Header size="h3">Station information</Header>
        {p.station && 
        <Table striped>
          <tbody>
            <tr>
              <td style={{width: '33%'}}>Station</td>
              <td>{p.station.name}</td>
            </tr>
          </tbody>
        </Table>}
      </Pane>
    )
  }
}

export { StationPane };
