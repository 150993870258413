import { IRoutes, IMenu } from '../Types';

import { Dashboard } from '../components/Dashboard';
import { Account } from '../components/Account';
import { LogList } from '../components/Log';
import { SignIn } from '../components/Signin/Signin';
import { ResetPassword } from '../components/ResetPassword';

import { UserList, ViewUser, AddUser, EditUser } from '../components/User';
import { ProfileList, ViewProfile, AddProfile, EditProfile  } from '../components/Profile';
import { StationList, ViewStation, AddStation, EditStation } from '../components/Station';
import { ViewLandslide, LandslideList, AddLandslide, EditLandslide } from '../components/Landslide';

import { Map } from '../components/Map';
import { RoadList } from '../components/Road/RoadList';
import { ViewRoad } from '../components/Road/ViewRoad';
import { IssueList } from '../components/Issue/IssueList';
import { ViewIssue } from '../components/Issue/ViewIssue';
import { AddIssue } from '../components/Issue/AddIssue';
import { EditIssue } from '../components/Issue/EditIssue';

/*
 * The following table contains all the routes available in the web application.
 * Each route has a unique key, which can be used to refer to it when building menus.
 * 
 * Each route is defined with:
 * - path:      URL for the route (after the hash)
 * - requireUser: true, right:    Optional. Users must be logged-in and have this permission to access the route.
 * - title:     Title that appears at the top of the screen.
 * - component: Route component to load.
 */

let routes: IRoutes = {
  signin:            { right: false, path: "/", title: "Sign in", component: SignIn },
  dashboard:         { right: true, path: "/", title: "Dashboard", component: Dashboard },
  map:               { right: true, path: "/map", title: "Map", component: Map },
  
  account:           { right: true, path: "/account", title: "Account", component: Account },
  reset:             { right: false, path: "/reset", title: "Reset Password", component: ResetPassword },
  users_list:        { right: 'can_view_users', path: "/users",  title: "Users",  component: UserList  },
  users_add:         { right: 'can_edit_users', path: "/users/add", title: "Add User", component: AddUser },
  users_edit:        { right: 'can_edit_users', path: "/users/edit/:id", title: "Edit user", component: EditUser },
  users_view:        { right: 'can_view_users', path: "/users/:id", title: "User", component: ViewUser },
  profiles_list:     { right: 'can_view_profiles', path: "/profiles",  title: "Profiles",  component: ProfileList },
  profiles_add:      { right: 'can_edit_profiles', path: "/profiles/add", title: "Add profile", component: AddProfile },
  profiles_edit:     { right: 'can_edit_profiles', path: "/profiles/edit/:id", title: "Edit profile", component: EditProfile },
  profiles_view:     { right: 'can_view_profiles', path: "/profiles/:id", title: "Profile", component: ViewProfile },
  logs_list:         { right: 'can_view_logs', path: "/logs", title: "Logs", component: LogList },

  roads_list:        { right: true, path: '/roads', title: "Roads", component: RoadList },
  roads_view:        { right: true, path: '/roads/:id', title: "Road", component: ViewRoad },

  stations_list:     { right: 'can_view_stations', path: '/stations', title: "Stations", component: StationList },
  stations_add:      { right: 'can_edit_stations', path: "/stations/add", title: "Add station", component: AddStation },
  stations_edit:     { right: 'can_edit_stations', path: "/stations/edit/:id", title: "Edit station", component: EditStation },
  stations_view:     { right: 'can_edit_stations', path: "/stations/:id", title: "Station", component: ViewStation },

  landslides_list:   { right: true, path: '/landslides', title: "Landslides list", component: LandslideList },
  landslides_add:    { right: 'can_edit_landslides', path: "/landslides/add", title: "Add landslide", component: AddLandslide },
  landslides_edit:   { right: 'can_edit_landslides', path: "/landslides/edit/:id", title: "Edit landslide", component: EditLandslide },
  landslides_view:   { right: true, path: "/landslides/:id", title: "Landslide", component: ViewLandslide },

  issues_list:       { right: true, path: '/issues', title: "Road reserve issues list", component: IssueList },
  issues_add:        { right: 'can_edit_issues', path: "/issues/add", title: "Add issue", component: AddIssue },
  issues_edit:       { right: 'can_edit_issues', path: "/issues/edit/:id", title: "Edit issue", component: EditIssue },
  issues_view:       { right: true, path: '/issues/:id', title: "Road reserve issue", component: ViewIssue }

};

/*
 * The application's main menu consists of groups and items.
 * A group has a key (its name), and an object containing items.
 * Each object has:
 * - A key (the item label)
 * - A reference to a route to load.
 * Menu items are only rendered if route conditions are satisfied.
 */
let menu: IMenu = {
  "Data": {
    "Dashboard": routes['dashboard'],
    "Map": routes['map'],
    "Landslides list": routes['landslides_list'],
    "Road reserve issues list": routes['issues_list'],
    "Roads": routes['roads_list']
  },
  "Users": {
    "Users": routes['users_list'],
    "Profiles": routes['profiles_list'],
    "Log": routes['logs_list']
  },
  "Administration": {
    "Stations": routes['stations_list']
  }
} 

export { routes, menu };