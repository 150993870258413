import * as React from 'react';
import { Profile, ProfileFactory, Station, StationFactory, User, UserFactory } from '../../resource/'; 
import { formatDistanceToNow, parse } from 'date-fns';
import { IAuthProps, List, IListState, Query, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export, NA } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Gravatar } from '@independent-software/typeui/controls/Gravatar';
import { Input } from '@independent-software/typeui/controls/Input';

interface IListUsersProps extends IListProps {
  /**
   * If set, list is prefiltered by a profile and
   * profile column and filter are hidden.
   */
  profileId?: number;
  /**
   * If set, list is prefiltered by a station and
   * station column and filter are hidden.
   */
  stationId?: number;  
  /**
   * Event is fired when a user is clicked.
   */
  onClick?: (user:User) => void;
}

interface IListUsersState extends IListState<User> {
  // Data for Profiles dropdown
  profiles: Profile[];
  // Date for Stations dropdown
  stations: Station[];
}

class ListUsers extends List<User, IListUsersProps, IListUsersState> {
  constructor(props: IAuthProps & IListUsersProps) {
    super(props, UserFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      profiles: [],
      stations: []
    };

    // The profile stored in the Query is an ID, not a Profile.
    // Is the list prefiltered by a profile? Then set a filter.
    if(this.props.profileId) {
      this.setFilter('profile', 'eq', { id: this.props.profileId }, true);
    }

    if(this.props.stationId) {
      this.setFilter('station', 'eq', { id: this.props.stationId }, true);
    }
  }

  componentDidMount() {
    if(!this.props.profileId) this.handleProfileSearch();    
    if(!this.props.stationId) this.handleStationSearch();    
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeProfile = (value: Profile) => {
    this.setFilter('profile', 'eq', value);
  }

  handleProfileSearch = (q?:string) => {
    // Retrieve a list of profiles:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    ProfileFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ profiles: res.items }));
  }

  handleChangeStation = (value: Station) => {
    this.setFilter('station', 'eq', value);
  }

  handleStationSearch = (q?:string) => {
    // Retrieve a list of stations:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    StationFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ stations: res.items }));
  }

  handleChangeFromDate = (value:string) => {
    if(value != null) value = value + " 00:00:00";
    this.setFilter('date', 'gte', value);
  }  

  handleChangeToDate = (value:string) => {
    if(value != null) value = value + " 23:59:59";
    this.setFilter('date', 'lte', value);
  }   

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search records">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.profileId && 
          <Form.Uncontrolled hint="Filter by profile">
            <Dropdown onSearch={this.handleProfileSearch} name="profile" fluid clearable value={this.getFilter('profile', 'eq')} data={this.state.profiles} placeholder="Profile" label={(item:Profile) => item.name} onChange={this.handleChangeProfile}>
              <Dropdown.Column weight={3}>{(item:Profile) => item.name}</Dropdown.Column>
              <Dropdown.Column align='right'>{(item:Profile) => item.users_count}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}
        {!p.stationId && 
          <Form.Uncontrolled hint="Filter by station">
            <Dropdown onSearch={this.handleStationSearch} name="station" fluid clearable value={this.getFilter('station', 'eq')} data={this.state.stations} placeholder="Station" label={(item:Station) => item.name} onChange={this.handleChangeStation}>
              <Dropdown.Column weight={3}>{(item:Station) => item.name}</Dropdown.Column>
              <Dropdown.Column align='right'>{(item:Station) => item.users_count}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}
        <Form.Uncontrolled hint="Filter last seen by start date">
          <Input name="from" type="date" fluid clearable value={this.getFilter('date', 'gte')} placeholder="From date" onChange={this.handleChangeFromDate}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="Filter last seen by end date">
          <Input name="to" type="date" fluid clearable value={this.getFilter('date', 'lte')} placeholder="To date" onChange={this.handleChangeToDate}/>
        </Form.Uncontrolled>              
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon={{name:'tools', title: 'Filter', inverted: true, cornered: true, bordered: true}} width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={2} label="Name" order="name" dir="asc">{(item:User) => <span><Gravatar email={item.email}/> {item.name}</span>}</DataTable.Column>
          {!p.profileId && <DataTable.Column label="Profile">{(item:User) => item.profile ? item.profile.name : <NA/>}</DataTable.Column>}
          <DataTable.Column label="Station">{(item:User) => item.station ? item.station.name : <NA/>}</DataTable.Column>
          <DataTable.Column label="Last seen" order="login_at" dir="desc">{(item:User) => item.login_at ? (formatDistanceToNow(parse(item.login_at.toString(), 'yyyy-MM-dd HH:mm:ss', new Date())) + ' ago') : <NA/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListUsers };
