const SYMBOL = {
  path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z",
  anchor: new google.maps.Point(256, 512),
  scale: 0.07,
  strokeWeight: 2,
  fillColor: '#ddd',
  fillOpacity: 0.9,
  strokeColor: '#333'
};

const CIRCLE = {
  path: "M354.29,256A100.75,100.75,0,1,1,253.54,155.25,100.75,100.75,0,0,1,354.29,256Z",
  anchor: new google.maps.Point(256, 256),
  scale: 0.07,
  strokeWeight: 2,
  fillColor: '#008080',
  fillOpacity: 0.9,
  strokeColor: '#333'
}

export { SYMBOL, CIRCLE };