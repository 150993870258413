import * as React from 'react';
import { Container, Content, BottomBar, HelpPane } from '../../modules';
import { Road } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListRoads } from './ListRoads';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Header } from '@independent-software/typeui/controls/Header';

interface IState {
  showHelp: boolean;
}

class RoadList extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      showHelp: false
    };
  }

  // Go to ViewRoad view.
  handleClick = (item: Road) => {
    this.props.history.push(`/roads/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListRoads auth={p.auth} name="roads" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          <div></div>
          <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>
        </BottomBar>
        <HelpPane title="Roads help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
          <p>The <b>roads list</b> shows roads stored in the database.</p>
          <Header size="h3">What's here?</Header>
          <p>
            This list presents roads by code, link, class, surface and length. It also shows the number of 
            landslides that occurred on each road link.
          </p>
          <Header size="h3">Viewing a road</Header>
          <p>
            You can click a record in the list to view all details for a road.
          </p>
          <Header size="h3">Sorting the list</Header>
          <p>
            You can sort the list by any column by clicking the list column headers.
          </p>
          <Header size="h3">Filtering the list</Header>
          <p>
            Use the <Icon name="tools"/> icon to filter the list. You can filter by road code or name, 
            and by class.
          </p>
          <Header size="h3">Exporting data</Header>
          <p>
            Any data you currently see can be exported to Microsoft Excel, Open Office or CSV formats. Your
            current ordering or list filter settings will be reflected in the exported data. To export,
            use the <Icon name="tools"/> icon and click <b>Export</b>.
          </p>
        </HelpPane>        
      </Container>
    );
  }
}

export { RoadList };
