import { Model, ResourceFactory } from '../services/';
import { IMBR } from './IMBR';

class Road extends Model implements IMBR {
  public road: string = '';
  public linkid: string = '';
  public link_name: string = '';
  public class: string = '';
  public surface_cl: string = '';
  public l_km: number = 0;
  public station: string = '';
  public region: string = '';
  public start_km: number = 0;
  public end_km: number = 0;
  public minLat: number;
  public minLng: number;
  public maxLat: number;
  public maxLng: number;
  public landslides_count: number = 0;
  public issues_count: number = 0;
}

let RoadFactory = ResourceFactory.create<Road>(Road, 'road');

export { Road, RoadFactory };