import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Panel } from '@independent-software/typeui/controls/Panel';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

interface IState {
  open: boolean;
}

class SearchPanelBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false
    }
  }

  handleOpenPanel = () => {
    this.setState({
      open: true
    })
  }

  handleClosePanel = () => {
    this.setState({
      open: false
    })
  }    

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Icon color="#333" cornered inverted name='search' onClick={this.handleOpenPanel}/>
        <Panel open={this.state.open} width={500} padded onClose={this.handleClosePanel}>
          {p.children}
        </Panel>
      </div>
    );
  }
}

const SearchPanel = styled(SearchPanelBase)`
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 1;
  padding: 0 20px 0 20px;
`

export { SearchPanel };
