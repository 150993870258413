import * as React from 'react';
import { Pane } from '@independent-software/typeui/controls/Pane';
import { Header } from '@independent-software/typeui/controls/Header';
import { Table } from '@independent-software/typeui/controls/Table';
import { Number } from '@independent-software/typeui/formatters/Number';
import { IRoad } from '../types/IRoad';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Button } from '@independent-software/typeui/controls/Button';
import { useHistory } from "react-router-dom";

interface IProps {
  road: IRoad;
  onClose: () => void;
}

const NavButton = (props: { id: number }) => {
  const history = useHistory();
  return <Button onClick={() => history.push(`/roads/${props.id}`)}>View details</Button>;
}

class RoadPane extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <Pane padded open={!!p.road} onClose={p.onClose}>
        <Header size="h3">Road information</Header>
        {p.road &&
        <React.Fragment>
          <Table striped>
            <tbody>
              <tr>
                <td style={{width: '33%'}}>Road</td>
                <td>{p.road.r}</td>
              </tr>
              <tr>
                <td>LinkID</td>
                <td>{p.road.l}</td>
              </tr>
              <tr>
                <td>Link name</td>
                <td>{p.road.ln}</td>
              </tr>
              <tr>
                <td>Length</td>
                <td><Number value={p.road.len/1000} decimals={2}/> km</td>
              </tr>
            </tbody>
          </Table>
          <Divider hidden/>
          <NavButton id={p.road.id}/>
        </React.Fragment> 
        }
      </Pane>
    )
  }
}

export { RoadPane };
