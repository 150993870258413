import * as React from 'react';
import styled, { Theme } from '@independent-software/typeui/styles/Theme'

import { Issue, Road, RoadFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';
import { Block, Section } from '../../modules';
import { SingleMarkerMap } from '../../map';

import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Label } from '@independent-software/typeui/controls/Label';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Message } from '@independent-software/typeui/controls/Message';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Button } from '@independent-software/typeui/controls/Button';
import { CIRCLE } from '../../map/Symbol';

interface IProps {
  /** Initial form data. */
  data: Issue;
  /** Called whenever form changes. */
  onChange: (data: Issue, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  showLocationDialog: boolean;
  /** Current form data. */
  data: Issue;
  /** Data for roads dropdown */
  roads: Road[];    
}

class IssueForm extends React.Component<IAuthProps & IProps, IState> {
  private latitude: number;
  private longitude: number;

  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      roads: [],
      showLocationDialog: false
    };    
  }

  componentDidMount() {
    // Retrieve a list of roads:
    RoadFactory.getSome(this.props.auth, 0, 10, new Query('linkid', 'asc'))
      .then((res) => this.setState({ roads: res.items }));    
  }

  handleRoadSearch = (q:string) => {
    // Retrieve a list of roads:
    let query = new Query('linkid', 'asc');
    query.setFilter('q', 'like', q);
    RoadFactory.getSome(this.props.auth, 0, 10, query)
      .then((res) => this.setState({ roads: res.items }));
  }    

  handleClickMap = (lat: number, lng: number) => {
    this.latitude = lat;
    this.longitude = lng;
    this.setState({
      showLocationDialog: true
    });
  }

  handleStartCoordinate = () => {
    this.props.onChange({...this.state.data, latitude_start: this.latitude.toString(), longitude_start: this.longitude.toString() }, true);
    this.setState({ showLocationDialog: false });
  }

  handleEndCoordinate = () => {
    this.props.onChange({...this.state.data, latitude_end: this.latitude.toString(), longitude_end: this.longitude.toString() }, true);
    this.setState({ showLocationDialog: false });
  }

  handleCloseDialog = () => {
    this.setState({ showLocationDialog: false });
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label="General">
            <Stretch>
              <StretchInner>
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Basic data">
                        <Form.Field 
                          required={{message: "Title is required"}}
                          label="Title"
                          name="title" 
                          value={this.state.data.title}
                          control={(<Input type="text" placeholder="Title" fluid/>)}
                          hint={(<React.Fragment>Enter a title for the issue.</React.Fragment>)}/>

                        <Form.Field
                          required={{message: "Type is required"}}
                          name="type"
                          value={this.state.data.type}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="type" value="inadequate_width" label="UNRA inability to manage roadside slopes due to lack of or limited road reserve width" checked={this.state.data.type=='inadequate_width'} onChange={() => this.props.onChange({...this.state.data, type: 'inadequate_width'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="type" value="adverse_land_use" label="Land management practices outside the road reserve impacting road reserve maintenance" checked={this.state.data.type=='adverse_land_use'} onChange={() => this.props.onChange({...this.state.data, type: 'adverse_land_use'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="type" value="unauthorized_land_use" label="Unauthorized occupation and/or commercial use of the road reserve" checked={this.state.data.type=='unauthorized_use'} onChange={() => this.props.onChange({...this.state.data, type: 'unauthorized_use'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>Select the type of issue</React.Fragment>}/>
                      </Block>            
                    </div>
                    <div>
                      <Block title="Description">
                        <Form.Field 
                          name="description" value={this.state.data.description}
                          control={(<MarkdownTextarea rows={10} fluid/>)}
                          hint={(<React.Fragment>Please provide a description of the issue.</React.Fragment>)}/>
                      </Block>
                    </div>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>

          <Tabs.Pane label="Location">
            <Stretch>
              <StretchInner>
                <SingleMarkerMap 
                  auth={p.auth}
                  lat={parseFloat(this.state.data.latitude_start)} 
                  lng={parseFloat(this.state.data.longitude_start)}
                  lat2={parseFloat(this.state.data.latitude_end)} 
                  lng2={parseFloat(this.state.data.longitude_end)} 
                  onClick={this.handleClickMap}
                  symbol={CIRCLE}
                />
                <Section padded>
                  <Message type="info">
                    Click the map to set an issue start location.
                  </Message>

                  <Block title="Road">
                    <Form.Field
                      width={1} label="Road"
                      name="road" 
                      value={this.state.data.road}
                      control={(<Dropdown onSearch={this.handleRoadSearch} fluid clearable data={this.state.roads} placeholder="Road" label={(item:Road) => <span>{item.road} &mdash; {item.linkid} &mdash; {item.link_name}</span>}>
                        <Dropdown.Column weight={1}>{(item:Road) => item.road}</Dropdown.Column>
                        <Dropdown.Column weight={2}>{(item:Road) => item.linkid}</Dropdown.Column>
                        <Dropdown.Column weight={4}>{(item:Road) => item.link_name}</Dropdown.Column>
                      </Dropdown>)}
                      hint="Select road where the landslide occurred"/>                          
                  </Block>

                  <Flex.Columns count={2}>
                    <Block title="Start location">
                      <Form.Field 
                        label="Chainage start"
                        name="chainage_start" 
                        value={this.state.data.chainage_start}
                        control={(<Input type="text" placeholder="Chainage start" fluid/>)}
                        hint={(<React.Fragment>Chainage where the issue starts</React.Fragment>)}/>
                      <Form.Field 
                        forceupdate
                        required={{message: "Start Latitude is required"}}
                        isFloat={{message: "Start Latitude must be a number"}}
                        label="Start Latitude"
                        name="latitude_start" 
                        value={this.state.data.latitude_start}
                        control={(<Input type="text" placeholder="Start latitude" fluid/>)}
                        hint={(<React.Fragment>Latitude where issue starts, e.g. <code>1.495731</code>. Use <code>0</code> if unknown</React.Fragment>)}/>
                      <Form.Field 
                        forceupdate
                        required={{message: "Start longitude is required"}}
                        isFloat={{message: "Start longitude must be a number"}}
                        label="Start Longitude"
                        name="longitude_start" 
                        value={this.state.data.longitude_start}
                        control={(<Input type="text" placeholder="Start longitude" fluid/>)}
                        hint={(<React.Fragment>Lontigude where issue starts, e.g. <code>32.630194</code>. Use <code>0</code> if unknown</React.Fragment>)}/>

                    </Block>
                    <Block title="End location">
                      <Form.Field 
                        label="Chainage end"
                        name="chainage_end" 
                        value={this.state.data.chainage_end}
                        control={(<Input type="text" placeholder="Chainage end" fluid/>)}
                        hint={(<React.Fragment>Chainage where the issue ends</React.Fragment>)}/>
                      <Form.Field 
                        forceupdate
                        required={{message: "End Latitude is required"}}
                        isFloat={{message: "End Latitude must be a number"}}
                        label="End Latitude"
                        name="latitude_end" 
                        value={this.state.data.latitude_end}
                        control={(<Input type="text" placeholder="End latitude" fluid/>)}
                        hint={(<React.Fragment>Latitude where issue ends, e.g. <code>1.495731</code>. Use <code>0</code> if unknown</React.Fragment>)}/>
                      <Form.Field 
                        forceupdate
                        required={{message: "End longitude is required"}}
                        isFloat={{message: "End longitude must be a number"}}
                        label="End Longitude"
                        name="longitude_end" 
                        value={this.state.data.longitude_end}
                        control={(<Input type="text" placeholder="End longitude" fluid/>)}
                        hint={(<React.Fragment>Lontigude where issue ends, e.g. <code>32.630194</code>. Use <code>0</code> if unknown</React.Fragment>)}/>
                    </Block>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>

        </Tabs>
        
        <Dialog open={this.state.showLocationDialog} onClose={this.handleCloseDialog}>
          <Dialog.Header>Select coordinates</Dialog.Header>
          <Dialog.Content>
            <p>Would you like to set the road reserve issue's start or end coordinate from this map location?</p>
          </Dialog.Content>
          <Dialog.Footer>
            <Button primary onClick={this.handleStartCoordinate}>
              Set start coordinate
            </Button>
            <Button primary onClick={this.handleEndCoordinate}>
              Set end coordinate
            </Button>
            <div style={{float: 'left'}}>
              <Button secondary onClick={this.handleCloseDialog}>
                Cancel
              </Button>
            </div>
          </Dialog.Footer>
        </Dialog>

      </Form>
    )
  }
}

const Padding = styled('div')`
  padding: 4px 0 4px 0;
`

const Question = styled('div')`
  font-style: italic;
  color: #888;
  margin-top: 4px;
  margin-bottom: 2px;
`

const Stretch = styled('div')`
  position: relative;
  height: 100%;
  margin-top: -14px;
`

const StretchInner = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -14px;
  overflow-y: scroll;
`

export { IssueForm };
