import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Container, Content, Section } from '../../modules';

import { Message } from '@independent-software/typeui/controls/Message';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Divider } from '@independent-software/typeui/controls/Divider';

class AccessDenied extends React.Component<{}, {}> {
  render() {
    return (
      <Container>
        <Content>
          <Section padded>
            <Divider hidden/>
            <Message icon type="error">
              <Icon name="lock" size="big"/>
              <Message.Content>
                <Message.Header>
                  Access denied
                </Message.Header>
                <p>
                  You do not have sufficient privileges to access this area of the application.
                </p>
              </Message.Content>
            </Message>
          </Section>
        </Content>
      </Container>
    );
  }
}

export { AccessDenied };
