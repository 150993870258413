import { StampedModel, ResourceFactory } from '../services/';

class User extends StampedModel {
  public name: string = '';
  public email: string = '';
  public password: string = '';
  public api_token: string = '';
  public login_at: Date = null;
  public sendmail: boolean = true;
  public profile: { 
    id: number;
    name: string;
  } = null;
  public station: {
    id: number;
    name: string;
  } = null;

  public nameAndEmail() {
    return this.name + ' ' + this.email;
  }
}

let UserFactory = ResourceFactory.create<User>(User, 'user');

export { User, UserFactory };