import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { CurrentUser } from '../CurrentUser';
import { Auth } from '../../services/Auth';
import { History } from 'history';

import { Icon } from '@independent-software/typeui/controls/Icon'

interface ITopbarProps {
  className?: string;
  children?: React.ReactNode;
  onMenu?: any;
  auth: Auth;
  onSignout: () => void;
  history: History;
}

interface ITopbarState {
  signinOpen: boolean;
}

class TopbarBase extends React.Component<ITopbarProps, ITopbarState> {
  constructor(props: ITopbarProps) {
    super(props);
    this.state = {
      signinOpen: false
    }
  }

  handleMenu = () => {
    if(this.props.onMenu) this.props.onMenu();
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <div>
          <MenuIcon name="bars" onClick={this.handleMenu}/>
          <Title>{p.children}</Title>
        </div>
        <CurrentUser history={p.history} auth={p.auth} onSignout={p.onSignout}/>
      </div>
    );
  }
}

const Title = styled('span')`
  font-family: 'Roboto Condensed';
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  /* If there is a menu icon, title adds some padding. */
  &:not(:first-child) {
    padding-left: 10px;
  }
`

const MenuIcon = styled(Icon)`
  /* The menu toggle icon only shows up when
   * the screen is small. */
  display: none;
  @media (max-width: ${p => p.theme.smallScreen}px) {
    display: inline;
  }  
`

const Topbar = styled(TopbarBase)`
  display: flex;
  z-index: 999;
  justify-content: space-between;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 0px 4px 0px;
  padding: 16px 20px 16px 20px;
  background: #fdfdfd;
`

export { Topbar };
