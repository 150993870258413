import * as React from 'react';
import { Container, Content, BottomBar } from '../../modules';
import { Station } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListStations } from './ListStations';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class StationList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
  }

  // Go to add station view.
  handleAdd = () => {
    this.props.history.push('/stations/add');
  }  

  // Go to view station view.
  handleClick = (item: Station) => {
    this.props.history.push(`/stations/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListStations auth={p.auth} name="stations" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth && p.auth.hasRight('can_edit_stations') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> Add</Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { StationList };
