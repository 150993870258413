import * as React from 'react';
import { MapLayer } from './MapLayer';
import { IRoad } from '../types/IRoad';

class RoadsLayer extends MapLayer<IRoad> {

  protected getResource = (): string => {
    return "roads";
  }

  protected getStyle = (item: IRoad, zoom: number): google.maps.Data.StyleOptions => {
    let strokeColor = '';
    switch(item.c) {
      case 'A':    strokeColor = '#FFF2AF'; break;   // asphalt
      case 'B':    strokeColor = '#FFFFFF'; break;   // gravel
      case 'C':    strokeColor = '#EEEEEE'; break;   // sand
    }
    
    // On zoom level 18, weight is 18.
    // On zoom level 17, weight is 9.
    // On zoom level 16, weight is 4.5
    // (rounded up to 1)
    let weight = Math.ceil(18 / Math.pow(2, 18 - zoom)) + 1;
    let zIndex = 0;

    switch(item.c) {
      case 'A':    zIndex = 3; weight = weight + 2; break; 
      case 'B':    zIndex = 2; weight = weight + 1; break;
      case 'C':    zIndex = 1; weight = weight + 0; break;
    }
    return {
      strokeColor: item == this.props.selected ? 'red': strokeColor,
      strokeWeight: weight,
      zIndex: zIndex,
      clickable: true
    };
  }
}

export { RoadsLayer };