import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'
import { ISubmenu } from '../../Types';
import { Header } from './Header';
import { NavLink } from 'react-router-dom';
import { IAuthProps } from '../../services/Auth';

import { Ripple } from '@independent-software/typeui/controls/Ripple'
import { DARK, LIGHT } from '../../config/Colors';

interface IGroupProps {
  className?: string;
  /* Group title */
  title: string;
  /* Is this group currently open? */
  open: boolean;
  /* Submenu to show in group */
  menu: ISubmenu;
  
  onClick: (key: string) => void;
}

class GroupBase extends React.Component<IAuthProps & IGroupProps, {}> {
  constructor(props: IAuthProps & IGroupProps) {
    super(props);
  }

  handleHeaderClick = () => {
    this.props.onClick(this.props.title);
  }

  render() {
    let p = this.props;

    // If there any route available to which the current user
    // has the right to go?
    let anyRouteAvailable = Object.keys(p.menu).some((key) => p.menu[key].right === false || (p.auth && p.auth.hasRight(p.menu[key].right)));

    // Only render group if there is at least one available route.
    if(!anyRouteAvailable) return null;
    return (
      <div className={p.className}>
        <Header open={p.open} onClick={this.handleHeaderClick}>{p.title}</Header>
        <List>
          {Object.keys(p.menu).map(function(key, index) {
            let route = p.menu[key];
            if(route.right === false || (p.auth && p.auth.hasRight(route.right))) {
              return (
                <Item key={index} type="div">
                  <NavLink key={index} to={route.path} exact={route.path=='/'}>{key}</NavLink>
                </Item>)
            }
          })}
        </List>
      </div>
    );
  }
}

const List = styled('div')`
  height: auto;
  overflow-y: hidden;
  transition: max-height 0.3s ease;
`;

const Group = styled(GroupBase)`
  position: relative;
  ${List} {
    max-height: ${p => p.open ? '1000px' : '0px'};
  }
  margin-bottom: 8px;
`

const Item = styled(Ripple)`
  background: ${LIGHT}; 

  /* User cannot select item text.
   * This prevents accidental selection when clicking the header.
   */
  user-select: none;

  a {
    display: block;
    font-weight: 500;
    padding: 5px 10px;
    color: #fff;
    outline: none;
    text-decoration: none;
    &:link { color: #fff; }
    &:visited { color: #fff; }
    &:hover { color: #fff; }
    &:active { color: #fff; }
    &.active {
      background: ${DARK};
    }
  }
`

export { Group, ISubmenu };
