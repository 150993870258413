import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from './App';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { StyleReset, StyleBase, Theme } from '@independent-software/typeui/styles';
import { AppContainer } from './AppContainer';

App.run(() => 
  ReactDOM.render(
    (<React.Fragment>
      <StyleReset/>
      <StyleBase/>
      <ThemeProvider theme={Theme}>
        <AppContainer/>
      </ThemeProvider>
    </React.Fragment>),
    document.getElementById('root')
  ));

// Whenever webpack rebuilds the project, refresh the browser.
declare let module: any;
if (module.hot) {
  module.hot.accept(); 
}
