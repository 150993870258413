import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Gradient } from './Gradient';
import { Flex } from '@independent-software/typeui/controls/Flex';

interface IProps {
  className?: string;
}

class SusceptibilityGradientBase extends React.Component<IProps, {}> {
  render() {
    return (
      <div className={this.props.className}>
        <Flex.Quick>
          <Gradient left='low' right='high' stops={['#FFFFB2','#FECC5C','#FD8D3C','#E31A1C','#0C00BD']}/>
          <div style={{paddingLeft: '20px'}}>source: <a href="https://doi.org/10.1016/j.earscirev.2018.05.002" target="_blank">Broeckx, J et. al., 2018</a></div>
        </Flex.Quick>
      </div>
    )
  }
}

const SusceptibilityGradient = styled(SusceptibilityGradientBase)`
  font-size: 70%;
`

export { SusceptibilityGradient }