import * as React from 'react';
import axios from 'axios';
import styled, {Theme} from '@independent-software/typeui/styles/Theme'

import { Container, Content, BottomBar, Section, Stats, HelpPane, Block } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { App } from '../../App';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { ResponsiveContainer, CartesianGrid, Tooltip, XAxis, YAxis, Bar, Line, BarChart } from 'recharts';
import { darken } from '@independent-software/typeui/helper/darken';
import { Header } from '@independent-software/typeui/controls/Header';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Link } from '../../controls/Link';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Road, Station, StationFactory } from '../../resource';
import { Table } from '@independent-software/typeui/controls/Table';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Query, ValueStore } from '../../services';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Panel } from '@independent-software/typeui/controls/Panel';

interface IYear {
  year: number;
  count: number;
}

interface IState {
  loading: boolean;
  stations: Station[];
  station: Station;
  landslides: number;
  issues: number;
  landslidesByYear: IYear[];
  showHelp: boolean;
  roads_landslides: Road[];
  roads_issues: Road[];
  roadlength: number;
  affectedlength: number;
  injuries: number;
  fatalities: number;
  accidents: number;
  reinstatement_cost: number;
  damage_walls: number,
  damage_culverts: number;
  damage_bridges: number;
  damage_buildings: number;
  issues_inadequate_width: number;
  issues_adverse_land_use: number;
  issues_unauthorized_use: number;
}

class Dashboard extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      loading: true,
      stations: [],
      station: null,
      landslides: null,
      issues: null,
      landslidesByYear: [],
      showHelp: false,
      roads_landslides: [],
      roads_issues: [],
      roadlength: null,
      affectedlength: null,
      injuries: null,
      fatalities: null,
      accidents: null,
      reinstatement_cost: null,
      damage_walls: null,
      damage_culverts: null,
      damage_bridges: null,
      damage_buildings: null,
      issues_inadequate_width: null,
      issues_adverse_land_use: null,
      issues_unauthorized_use: null
    }
  }

  componentDidMount() {
    // Load stations from server:
    this.handleStationSearch();

    // Load data from the server.
    this.loadData();
  } 

  componentDidUpdate(prevProps: any, prevState: IState) {
    if(this.state.station != prevState.station) {
      this.loadData();
    }
  }

  private loadData = () => {
    this.setState({loading: true});
    axios.get(`${App.apiURL}dashboard${this.state.station ? `?station=${this.state.station.id}` : ''}`)
    .then(response => {
      let data = response.data;
      this.setState({
        loading: false,
        landslides: data.landslides,
        roads_landslides: data.roads_landslides,
        roads_issues: data.roads_issues,
        issues: data.issues,
        landslidesByYear: data.landslidesByYear,
        roadlength: data.roadlength,
        affectedlength: data.affectedlength,
        injuries: data.injuries,
        fatalities: data.fatalities,
        accidents: data.accidents,
        reinstatement_cost: data.reinstatement_cost,
        damage_walls: data.damage_walls,
        damage_culverts: data.damage_culverts,
        damage_bridges: data.damage_bridges,
        damage_buildings: data.damage_buildings,
        issues_inadequate_width: data.issues_inadequate_width,
        issues_adverse_land_use: data.issues_adverse_land_use,
        issues_unauthorized_use: data.issues_unauthorized_use
      })
    })
    .catch(error => {
    });    
  }

  handleChangeStation = (value: Station) => {
    this.setState({ station: value });
  }

  handleStationSearch = (q?:string) => {
    // Retrieve a list of stations:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    StationFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ stations: res.items }));
  }


  handleClickLandslides = () => {
    this.props.history.push('/landslides');
  }

  handleClickIssues = () => {
    this.props.history.push('/issues');
  }

  handleClickAddLandslide = () => {
    this.props.history.push('/landslides/add');
  }

  handleClickAddIssue = () => {
    this.props.history.push('/issues/add');
  }

  handleClickRoad = (id: number) => {
    this.props.history.push(`/roads/${id}`);
  }

  private handleTabChange = (index: number) => {
    ValueStore.set("dashboard", index);
  }

  render() {
    let p = this.props;
    return (
      <Container>
        <IconBar>
          <Panel.Icon icon={{name:'tools', title: 'Filter', inverted: true, cornered: true, bordered: true}} width={300}>
            <Panel.Content>
              <Form.Uncontrolled hint="Filter by station">
                <Dropdown onSearch={this.handleStationSearch} name="station" fluid clearable value={this.state.station} data={this.state.stations} placeholder="Station" label={(item:Station) => item.name} onChange={this.handleChangeStation}>
                  <Dropdown.Column>{(item:Station) => item.name}</Dropdown.Column>
                </Dropdown>
              </Form.Uncontrolled>
            </Panel.Content>
          </Panel.Icon>
        </IconBar>             
        <Content>
        {this.state.loading && <Loader/>}
        {!this.state.loading && 
          <React.Fragment>
            <Tabs underlined nohiddenrender active={ValueStore.get('dashboard', 0)} onTabChange={this.handleTabChange}>
              <Tabs.Pane label="Landslides">
                <Stretch>
                  <StretchInner>

                    <Section padded>

                      <Stats onClick={this.handleClickLandslides}>
                        <Stats.Title>Landslides</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.landslides}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Number of recorded landslides</Stats.Bottom>
                      </Stats>

                      <Stats>
                        <Stats.Title>Affected road length</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.affectedlength}/></Stats.BigNumber> m
                        </Stats.Center>
                        <Stats.Bottom>Length affected by landslides (<Number value={0.1*this.state.affectedlength/this.state.roadlength} decimals={3}/>%)</Stats.Bottom>
                      </Stats>            

                      <Stats>
                        <Stats.Title>Reinstatement cost</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.reinstatement_cost}/></Stats.BigNumber> UGX
                        </Stats.Center>
                        <Stats.Bottom>Total cost of reinstatement</Stats.Bottom>
                      </Stats>                

                      <Divider>People affected</Divider>

                      <Stats>
                        <Stats.Title>Injuries</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.injuries}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Injuries due to landslides</Stats.Bottom>
                      </Stats>

                      <Stats>
                        <Stats.Title>Fatalities</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.fatalities}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Fatalities due to landslides</Stats.Bottom>
                      </Stats>

                      <Stats>
                        <Stats.Title>Accidents</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.accidents}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Vehicular accidents due to landslides</Stats.Bottom>
                      </Stats>       

                      <Divider>Damage</Divider>

                      <Stats>
                        <Stats.Title>Walls</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.damage_walls}/></Stats.BigNumber> m
                        </Stats.Center>
                        <Stats.Bottom>Total length of retaining wall damaged</Stats.Bottom>
                      </Stats>    

                      <Stats>
                        <Stats.Title>Culverts</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.damage_culverts}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Total number of culverts damaged</Stats.Bottom>
                      </Stats>                    

                      <Stats>
                        <Stats.Title>Bridges</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.damage_bridges}/></Stats.BigNumber> m
                        </Stats.Center>
                        <Stats.Bottom>Total bridge span damaged</Stats.Bottom>
                      </Stats>                    

                      <Stats>
                        <Stats.Title>Buildings</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.damage_buildings}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Total number of buildings damaged</Stats.Bottom>
                      </Stats>                    

                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Road reserve issues">
                <Stretch>
                  <StretchInner>
                    <Section padded>
                      <Stats onClick={this.handleClickIssues}>
                        <Stats.Title>Road reserve issues</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.issues}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Number of recorded road reserve issues</Stats.Bottom>
                      </Stats>            
                      <Divider>Types</Divider>
                      <Stats>
                        <Stats.Title>Inadequate width</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.issues_inadequate_width}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>UNRA inability to manage roadside slopes due to lack of or limited road reserve width</Stats.Bottom>
                      </Stats>                                  
                      <Stats>
                        <Stats.Title>Adverse land use</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.issues_adverse_land_use}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Land management practices outside the road reserve impacting road reserve maintenance</Stats.Bottom>
                      </Stats>                       
                      <Stats>
                        <Stats.Title>Unauthorized use</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.issues_unauthorized_use}/></Stats.BigNumber>
                        </Stats.Center>
                        <Stats.Bottom>Unauthorized occupation and/or commercial use of the road reserve<br/>&nbsp;</Stats.Bottom>
                      </Stats>                                  
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Roads">
                <Stretch>
                  <StretchInner>
                    <Section padded>
                      <Stats>
                        <Stats.Title>Total road length</Stats.Title>
                        <Stats.Center>
                          <Stats.BigNumber><Number decimals={0} value={this.state.roadlength}/></Stats.BigNumber> km
                        </Stats.Center>
                        <Stats.Bottom>Total road network in database</Stats.Bottom>
                      </Stats>            
                      <Flex.Columns count={2}>
                        <Block title="Roads most affected by landslides">
                          <Table transparent striped>
                            <thead>
                              <tr>
                                <th style={{width: '20%'}}>Road</th>
                                <th style={{width: '60%'}}>Name</th>
                                <th style={{width: '20%', textAlign:'right'}}>Landslides</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.roads_landslides.map((r: Road) => 
                                <tr key={r.id} onClick={() => this.handleClickRoad(r.id)} style={{cursor: 'pointer'}}>
                                  <td>{r.road}</td>
                                  <td>{r.link_name}</td>
                                  <td style={{textAlign:'right'}}>{r.landslides_count}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Block>
                        <Block title="Roads most affected by road reserve issues">
                          <Table transparent striped>
                            <thead>
                              <tr>
                                <th style={{width: '20%'}}>Road</th>
                                <th style={{width: '60%'}}>Name</th>
                                <th style={{width: '20%', textAlign:'right'}}>Issues</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.roads_issues.map((r: Road) => 
                                <tr key={r.id} onClick={() => this.handleClickRoad(r.id)} style={{cursor: 'pointer'}}>
                                  <td>{r.road}</td>
                                  <td>{r.link_name}</td>
                                  <td style={{textAlign:'right'}}>{r.issues_count}</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Block>
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>
              
              <Tabs.Pane label="Time">
                <Section padded>
                  <Block title="Landslides by year">
                    <ResponsiveContainer width="100%" height={500}>
                      <BarChart
                        data={this.state.landslidesByYear}
                        margin={{
                          top: 5, right: 30, left: 0, bottom: 5,
                        }}
                      >
                        <CartesianGrid
                          strokeDasharray="6 3" 
                          strokeWidth={0.5}
                          stopOpacity={0.6}
                        />
                        <Tooltip content={<CustomTooltip/>} />
                        <XAxis 
                          dataKey="year" 
                          type='number' 
                          domain={[1900, new Date().getFullYear()]}
                          tick={{stroke:'#888', strokeWidth: 0.5, fontSize: 9, fontFamily: 'Roboto' }}
                        />
                        <YAxis 
                          domain={[0, 'auto']}
                          width={25}
                          type='number'
                          scale='linear'
                          tick={{stroke:'#888', strokeWidth: 0.5, fontSize: 9, fontFamily: 'Roboto' }}
                        />
                        <Bar 
                          dataKey="count" 
                          fill={Theme.primaryColor}
                          stroke={darken(0.1, Theme.primaryColor)}
                          strokeWidth={1}
                        />
                      </BarChart>         
                    </ResponsiveContainer>    
                  </Block>
                </Section>
              </Tabs.Pane>

            </Tabs>
          </React.Fragment>}

          </Content>
          <BottomBar>
            <div>
              {p.auth.hasRight('can_edit_landslides') && <Button primary onClick={this.handleClickAddLandslide}><Icon name="plus"/> Add new landslide</Button>}
              {p.auth.hasRight('can_edit_issues') && <Button primary onClick={this.handleClickAddIssue}><Icon name="plus"/> Add new road reserve issue</Button>}
            </div>
            <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>
          </BottomBar>
        

        <HelpPane title="Dashboard help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
            <p>Welcome to the <b>Landslide &amp; Road Reserve Management Toolkit!</b></p>
            <Header size="h3">What's here?</Header>
            <p>
              This <b>dashboard</b> provides you with an overview of the contents of the 
              landslides database. For detailed information, you can visit 
              the <Link to="landslides">landslides list</Link> or view 
              the <Link to="map">landslides map</Link> (in the left sidebar).
            </p>
            {p.auth.hasRight('can_edit_landslides') && <React.Fragment>
              <Header size="h3">Adding landslide data</Header>
              <p>To add new landslide information, you can either 
              click <Link to="landslides/add">Add new landslide</Link> or 
              go to the <Link to="landslides">landslides list</Link>.</p>
            </React.Fragment>}
            {p.auth.hasRight('can_edit_issues') && <React.Fragment>
              <Header size="h3">Adding road reserve issue data</Header>
              <p>To add new road reserve issue information, you can either 
              click <Link to="issues/add">Add new road reserve issue</Link> or 
              go to the <Link to="issues">issues list</Link>.</p>
            </React.Fragment>}

        </HelpPane>        

      </Container>
    );
  }
}

interface ITooltipProps {
  active?: boolean;
  payload?: any[];
  label?: number;
}

class CustomTooltip extends React.Component<ITooltipProps, {}> {
  render() {
    if(!this.props.active) return null;
    return (
      <div>
        <Segment raised tight secondary attached='top'>
          <div style={{fontSize: '80%'}}>{this.props.label}</div>
        </Segment>
        <Segment raised tight attached='bottom'>
          {(this.props.payload && this.props.payload[0]) ? 
            <React.Fragment>
              <Number value={(this.props.payload[0] as any).value} decimals={0}/> 
              &nbsp;
              {(this.props.payload[0] as any).value == 1 && "landslide"}
              {(this.props.payload[0] as any).value != 1 && "landslides"}
            </React.Fragment>
          : '-'}
        </Segment>
      </div>
    )
  }
}

const Stretch = styled('div')`
  position: relative;
  height: 100%;
  margin-top: -14px;
`

const StretchInner = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -14px;
  overflow-y: scroll;
`

const IconBar = styled('div')`
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 8px;
  & > * {
    margin-left: 8px;
  }
`

export { Dashboard };
