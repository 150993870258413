import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'

import { Ripple } from '@independent-software/typeui/controls/Ripple'
import { darken } from '@independent-software/typeui/helper/darken';
import { LIGHT } from '../../config/Colors';

interface IHeaderProps {
  className?: string;
  children?: React.ReactNode;
  open: boolean;
  onClick?: any;
}

class HeaderBase extends React.Component<IHeaderProps, {}> {
  render() {
    let p = this.props;
    return (
      <Ripple type="div" className={p.className} onClick={p.onClick}>
        <span>{p.children}</span>
        <svg><use xlinkHref={"spritemap.svg#caret-down"}></use></svg>
      </Ripple>
    );
  }
}

const Header = styled(HeaderBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: color ease 0.1s;
  padding: 4px 10px 4px 10px;
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
  border-bottom: solid 1px ${darken(0.05, LIGHT)};
  background: ${LIGHT};
  font-weight: 400;

  /* User cannot select header text.
   * This prevents accidental selection when clicking the header.
   */
  user-select: none;  

  /* Icon rotates when active */
  & > svg {
    width: 17px;
    height: 17px; 
    transform: rotate(90deg);
    
    fill: #fff;
    transition: transform ease 0.1s;
  }
  ${p => p.open && css`
    & > svg {
      transform: rotate(0deg);
    }
  `}

  /* Separate title slightly from icon. */
  & > span {
    padding-left: 2px;
  }  
`


export { Header };
