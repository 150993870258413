import * as React from 'react';
import { Pane } from '@independent-software/typeui/controls/Pane';
import { Header } from '@independent-software/typeui/controls/Header';
import { Table } from '@independent-software/typeui/controls/Table';
import { Button } from '@independent-software/typeui/controls/Button';
import { useHistory } from "react-router-dom";
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Landslide } from '../../resource';
import { NA } from '../../modules';
import { Number } from '@independent-software/typeui/formatters/Number';

interface IProps {
  landslide: Landslide;
  onClose: () => void;
}

const NavButton = (props: { id: number }) => {
  const history = useHistory();
  return <Button onClick={() => history.push(`/landslides/${props.id}`)}>View details</Button>;
}

class LandslidePane extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <Pane padded open={!!p.landslide} onClose={p.onClose}>
        <Header size="h3">Landslide information</Header>
        {p.landslide && 
        <React.Fragment>
          <Table striped>
            <tbody>
              <tr>
                <td style={{width: '33%'}}>Date</td>
                <td>{p.landslide.date ? p.landslide.formatDateLong() : <NA/>}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{p.landslide.type ? p.landslide.formatType() : <NA/>}</td>
              </tr>
              {p.landslide.station &&
                <tr>
                  <td>Station</td>
                  <td>{p.landslide.station.name}</td>
                </tr>}
              <tr>
                <td>Latitude</td>
                <td><Number value={p.landslide.latitude} decimals={6}/></td>
              </tr>
              <tr>
                <td>Longitude</td>
                <td><Number value={p.landslide.longitude} decimals={6}/></td>
              </tr>
            </tbody>
          </Table>
          <Divider hidden/>
          <NavButton id={p.landslide.id}/>
        </React.Fragment>}
      </Pane>
    )
  }
}

export { LandslidePane };
