import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { GoogleMap } from '@react-google-maps/api'
import MarkerClusterer from '@google/markerclustererplus'
import { Landslide, IMBR, Issue } from '../resource';
import { BaseMapStyle } from './styles/BaseMapStyle';
import { RoadsLayer } from './layers/RoadsLayer';
import { RoadPane } from './panes/RoadPane';
import { LandslidePane } from './panes/LandslidePane';
import { Auth } from '../services';
import { StationsLayer } from './layers/StationsLayer';
import { StationPane } from './panes/StationPane';
import { LayerToggle } from './LayerToggle';
import { IRoad } from './types/IRoad';
import { IStation } from './types/IStation';
import { RoadSearch } from './RoadSearch';
import { SearchPanel } from './SearchPanel';
import { StationSearch } from './StationSearch';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { SYMBOL, CIRCLE } from './Symbol';
import { Input } from '@independent-software/typeui/controls/Input';
import { App } from '../App';
import { Gradient } from './Gradient';
import { MapControls } from './MapControls';
import { SusceptibilityGradient } from './SusceptibilityGradient';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { IssuePane } from './panes/IssuePane';

interface IProps {
  className?: string;
  // Needed for "view landslide" button and for searching roads/regions
  auth: Auth; 
  landslides: Landslide[];
  issues: Issue[];
}

interface IState {
  map: google.maps.Map;
  // Road to show in road pane
  road: IRoad;
  // Station to show in station pane
  station: IStation;
  // Landslide to show in landslide pane
  landslide: Landslide;
  // Issue to show in issue pane
  issue: Issue;
  // Layer visibility toggling
  showRoads: boolean;
  showStations: boolean;
  showSusceptibility: boolean;
  // Filters:
  filterLandslideType: string;
  filterIssueType: string;
  filterStartDate: string;
  filterEndDate: string;
  filterUndated: boolean;
  filterImported: boolean;
}

class ManyMarkersMapBase extends React.Component<IProps, IState> {
  private markers: google.maps.Marker[] = [];
  private clusterer: MarkerClusterer = null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      map: null,
      road: null,
      station: null,
      landslide: null,
      issue: null,
      showStations: false,
      showRoads: true,
      showSusceptibility: false,
      filterLandslideType: null,
      filterIssueType: null,
      filterStartDate: null,
      filterEndDate: null,
      filterUndated: false,
      filterImported: false
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    // Create markers from landslides/issues and store them in an array.
    if(this.props.landslides.length != prevProps.landslides.length ||
       this.props.issues.length != prevProps.issues.length) {
      this.createMarkers();
      this.filterMarkers();
    }

    // Filter markers, and add filtered markers to cluster.
    // Filter only if any of the filter properties have changed, 
    // so that the markers on the map don't flicker when they are clicked.
    if(this.state.filterLandslideType != prevState.filterLandslideType
    || this.state.filterIssueType != prevState.filterIssueType
    || this.state.filterStartDate != prevState.filterStartDate
    || this.state.filterEndDate != prevState.filterEndDate
    || this.state.filterUndated != prevState.filterUndated
    || this.state.filterImported != prevState.filterImported) {
      this.filterMarkers();
    }
  }

  // Create a marker for each landslide, skipping landslides that are not
  // in Uganda. Set the landslide as an attribute of each marker.
  createMarkers = () => {
    this.markers = [];
    this.props.landslides.forEach((landslide) => {
      let lat = parseFloat(landslide.latitude);
      let lng = parseFloat(landslide.longitude);
      // Skip landslides that have a location outside of Uganda:
      if(lat < -1.75 || lng < 29 || lat > 4.62 || lng > 35.55) return null;
      let marker = new google.maps.Marker({
        position: { lat: parseFloat(landslide.latitude), lng: parseFloat(landslide.longitude) },
        icon: SYMBOL
      });
      marker.addListener('click', () => this.handleClickLandslideMarker(landslide));
      // Save landslide as value of marker:
      marker.set("object", landslide);
      this.markers.push(marker);
    });

    this.props.issues.forEach((issue) => {
      let marker = new google.maps.Marker({
        position: { lat: parseFloat(issue.latitude_start), lng: parseFloat(issue.longitude_start) },
        icon: CIRCLE
      });
      marker.addListener('click', () => this.handleClickIssueMarker(issue));
      // Save landslide as value of marker:
      marker.set("object", issue);
      this.markers.push(marker);
    });
  }

  // Apply filters to markers, only placing markers in clusterer
  // that match the filter.
  filterMarkers = () => {
    this.clusterer.clearMarkers();
    this.markers.forEach(m => {
      let show = true;
      if(m.get("object") instanceof Landslide) {
        let landslide: Landslide = m.get("object");
        if(this.state.filterLandslideType && landslide.type != this.state.filterLandslideType) show = false;
        if(this.state.filterStartDate && (!landslide.date || landslide.date < this.state.filterStartDate)) show = false;
        if(this.state.filterEndDate && (!landslide.date || landslide.date > this.state.filterEndDate)) show = false;
        if(this.state.filterUndated && landslide.date == null) show = false;
        if(this.state.filterImported && landslide.imported == true) show = false;
      }
      if(m.get("object") instanceof Issue) {
        let issue: Issue = m.get("object");
        if(this.state.filterIssueType && issue.type != this.state.filterIssueType) show = false;
      }
      if(show) this.clusterer.addMarker(m);
    });
  }

  // When map has loaded, configure it and keep a reference to it.
  handleMapLoaded = (map: google.maps.Map) => {
    map.setMapTypeId("hybrid");
    map.setCenter({ lat: 1.49, lng: 32.63 });

    // Create marker clusterer:
    const imagePath = "img/m";
    this.clusterer = new MarkerClusterer(map, [], { imagePath: imagePath });

    this.toggleSusceptibility(map, this.state.showSusceptibility);

    // Keep a reference to the map to pass it on to layers:
    this.setState({
      map: map
    });
  }

  toggleSusceptibility(map: google.maps.Map, susceptibility: boolean) {
    map.overlayMapTypes.clear();
    if(!susceptibility) return;

    let imageMapType = new google.maps.ImageMapType({
      getTileUrl: function(coord: google.maps.Point, zoom: number) {
        let url = App.apiURL + 'tile/{z}/{x}/{y}'
          .replace('{x}', coord.x.toString())
          .replace('{y}', coord.y.toString())
          .replace('{z}', zoom.toString());
        return url;
      },
      tileSize: new google.maps.Size(256, 256),
      minZoom: 0,
      maxZoom: 18
    });
    map.overlayMapTypes.push(imageMapType);
  }

  handleClickRoad = (road: IRoad) => {
    this.setState({
      road: road,
      issue: null,
      station: null,
      landslide: null
    });
  }

  handleClickStation = (station: IStation) => {
    this.setState({
      road: null,
      issue: null,
      station: station,
      landslide: null
    });
  }  

  handleClickLandslideMarker = (landslide: Landslide) => {
    this.setState({
      landslide: landslide,
      issue: null,
      road: null,
      station: null
    });
  }

  handleClickIssueMarker = (issue: Issue) => {
    this.setState({
      issue: issue,
      landslide: null,
      road: null,
      station: null
    });
  }

  handleClosePanels = () => {
    this.setState({
      landslide: null,
      issue: null,
      road: null,
      station: null
    });
  }
  
  handleLayerToggle = (roads: boolean, stations: boolean, susceptibility: boolean) => {
    if(this.state.showSusceptibility != susceptibility) {
      this.toggleSusceptibility(this.state.map, susceptibility);
    }
    this.setState({
      showRoads: roads,
      showStations: stations,
      showSusceptibility: susceptibility
    });
  }

  handleZoomToMBR = (mbr: IMBR) => {
    let bounds = new google.maps.LatLngBounds(
      { lat: mbr.minLat, lng: mbr.minLng },
      { lat: mbr.maxLat, lng: mbr.maxLng },
    );
    this.state.map.setCenter(bounds.getCenter());
    this.state.map.fitBounds(bounds);
  }

  formatLandslideType = (type: string) => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  formatIssueType = (type: string) => {
    return type.charAt(0).toUpperCase() + type.slice(1).replace(/_/g, ' ');
  }

  handleChangeLandslideType = (value: string) => {
    this.setState({
      filterLandslideType: value
    });
  }

  handleChangeIssueType = (value: string) => {
    this.setState({
      filterIssueType: value
    });    
  }

  handleChangeStartDate = (value: string) => {
    this.setState({
      filterStartDate: value
    });
  }

  handleChangeEndDate = (value: string) => {
    this.setState({
      filterEndDate: value
    });
  }  

  handleChangeUndated = (value: boolean) => {
    this.setState({
      filterUndated: !value
    });
  }

  handleChangeImported = (value: boolean) => {
    this.setState({
      filterImported: !value
    });
  }

  renderFilterPanel = () => {
    return (
    <SearchPanel>
      {this.state.showRoads && <RoadSearch auth={this.props.auth} onSelect={this.handleZoomToMBR}/>}
      {this.state.showStations && <StationSearch auth={this.props.auth} onSelect={this.handleZoomToMBR}/>}
      <Form.Uncontrolled hint="Filter by landslide type">
        <Dropdown name="landslidetype" fluid clearable value={this.state.filterLandslideType} placeholder="Landslide type" 
          data={['new', 'reactivation', 'extension']}
          label={this.formatLandslideType}
          onChange={this.handleChangeLandslideType}>
          <Dropdown.Column>{this.formatLandslideType}</Dropdown.Column>
        </Dropdown>
      </Form.Uncontrolled>
      <Form.Uncontrolled hint="Filter landslides by start date">
        <Input name="from" type="date" fluid clearable value={this.state.filterStartDate} placeholder="From date" onChange={this.handleChangeStartDate}/>
      </Form.Uncontrolled>
      <Form.Uncontrolled hint="Filter landslides by end date">
        <Input name="to" type="date" fluid clearable value={this.state.filterEndDate} placeholder="To date" onChange={this.handleChangeEndDate}/>
      </Form.Uncontrolled>
      <Form.Uncontrolled hint="">
        <Checkbox label="Include imported landslides" name='empty' type="toggle" checked={!this.state.filterImported} onChange={this.handleChangeImported}/>
      </Form.Uncontrolled>
      <Form.Uncontrolled hint="">
        <Checkbox label="Include undated landslides" name='empty' type="toggle" checked={!this.state.filterUndated} onChange={this.handleChangeUndated}/>
      </Form.Uncontrolled>
      <Form.Uncontrolled hint="Filter by issue type">
        <Dropdown name="issuetype" fluid clearable value={this.state.filterIssueType} placeholder="Issue type" 
          data={['inadequate_width', 'adverse_land_use', 'unauthorized_use']}
          label={this.formatIssueType}
          onChange={this.handleChangeIssueType}>
          <Dropdown.Column>{this.formatIssueType}</Dropdown.Column>
        </Dropdown>
      </Form.Uncontrolled>
    </SearchPanel>
    );
  }

  render() {
    let p = this.props;
    // https://react-google-maps-api-docs.netlify.com/#googlemap
    return (
      <div className={p.className}>
        {this.renderFilterPanel()}
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          zoom={8}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            styles: BaseMapStyle
          }}
          onLoad={this.handleMapLoaded}/>
        <StationsLayer selected={this.state.station} map={this.state.map} onClick={this.handleClickStation} visible={this.state.showStations}/>
        <RoadsLayer selected={this.state.road} map={this.state.map} onClick={this.handleClickRoad} visible={this.state.showRoads}/>
        {this.state.showRoads && <RoadPane road={this.state.road} onClose={this.handleClosePanels}/>}
        {this.state.showStations && <StationPane station={this.state.station} onClose={this.handleClosePanels}/>}
        <LandslidePane landslide={this.state.landslide} onClose={this.handleClosePanels}/>
        <IssuePane issue={this.state.issue} onClose={this.handleClosePanels}/>
        <MapControls>
          <LayerToggle roads={this.state.showRoads} stations={this.state.showStations} susceptibility={this.state.showSusceptibility} onToggle={this.handleLayerToggle}/>
          {this.state.showSusceptibility && <SusceptibilityGradient/>}
        </MapControls>
      </div>
    )
  }
}

const ManyMarkersMap = styled(ManyMarkersMapBase)`
  width: 100%;
  height: 100%;
`

export { ManyMarkersMap };