import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api'
import { RoadsLayer } from './layers/RoadsLayer';
import { StationsLayer } from './layers/StationsLayer';
import { IStation } from './types/IStation';
import { IRoad } from './types/IRoad';
import { StationPane } from './panes/StationPane';
import { RoadPane } from './panes/RoadPane';
import { SearchPanel } from './SearchPanel';
import { RoadSearch } from './RoadSearch';
import { StationSearch } from './StationSearch';
import { IMBR } from '../resource';
import { Auth } from '../services';
import { LayerToggle } from './LayerToggle';
import { SYMBOL, CIRCLE } from './Symbol';
import { App } from '../App';
import { Gradient } from './Gradient'
import { MapControls } from './MapControls'
import { SusceptibilityGradient } from './SusceptibilityGradient';

interface IProps {
  className?: string;
  // Needed for searching roads/regions
  auth: Auth; 
  lat: number;
  lng: number;
  lat2?: number;
  lng2?: number;
  onClick?: (lat: number, lng: number) => void;
  symbol: any;
}

interface IState {
  map: google.maps.Map;
  // Road to show in road pane
  road: IRoad;
  // Station to show in station pane
  station: IStation;
  // Layer visibility toggling
  showRoads: boolean;
  showStations: boolean;  
  showSusceptibility: boolean;
}

class SingleMarkerMapBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      map: null,
      road: null,
      station: null,
      showRoads: true,
      showStations: false,
      showSusceptibility: false
    };
  }

  private handleMapLoaded = (map: google.maps.Map) => {
    let pos = { lat: this.props.lat, lng: this.props.lng };
    if (isNaN(this.props.lat) || isNaN(this.props.lng)) {
      pos = { lat: 1.49, lng: 32.63 };
    }
    map.setCenter(pos);

    this.toggleSusceptibility(map, this.state.showSusceptibility);

    // Keep a reference to the map and to pass it on to layers:
    this.setState({
      map: map
    });
  }

  toggleSusceptibility(map: google.maps.Map, susceptibility: boolean) {
    map.overlayMapTypes.clear();
    if(!susceptibility) return;

    let imageMapType = new google.maps.ImageMapType({
      getTileUrl: function(coord: google.maps.Point, zoom: number) {
        let url = App.apiURL + 'tile/{z}/{x}/{y}'
          .replace('{x}', coord.x.toString())
          .replace('{y}', coord.y.toString())
          .replace('{z}', zoom.toString());
        return url;
      },
      tileSize: new google.maps.Size(256, 256),
      minZoom: 0,
      maxZoom: 18
    });
    map.overlayMapTypes.push(imageMapType);
  }


  handleClickRoad = (road: IRoad) => {
    this.setState({
      road: road,
      station: null
    });
  }

  handleClickStation = (station: IStation) => {
    this.setState({
      road: null,
      station: station
    });
  }  

  handleClosePanels = () => {
    this.setState({
      road: null,
      station: null
    });
  }

  handleLayerToggle = (roads: boolean, stations: boolean, susceptibility: boolean) => {
    if(this.state.showSusceptibility != susceptibility) {
      this.toggleSusceptibility(this.state.map, susceptibility);
    }
    this.setState({
      showRoads: roads,
      showStations: stations,
      showSusceptibility: susceptibility
    });
  }

  private handleClick = (e: google.maps.MouseEvent) => {
    if(this.props.onClick) this.props.onClick(e.latLng.lat(), e.latLng.lng());
  }

  handleZoomToMBR = (mbr: IMBR) => {
    let bounds = new google.maps.LatLngBounds(
      { lat: mbr.minLat, lng: mbr.minLng },
      { lat: mbr.maxLat, lng: mbr.maxLng },
    );
    this.state.map.setCenter(bounds.getCenter());
    this.state.map.fitBounds(bounds);
  }  

  render() {
    let p = this.props;

    let pos = { lat: p.lat, lng: p.lng };
    if (isNaN(p.lat) || isNaN(p.lng)) {
      pos = null;
    }

    let pos2 = { lat: p.lat2, lng: p.lng2 };
    if (isNaN(p.lat2) || isNaN(p.lng2)) {
      pos2 = null;
    }

    // https://react-google-maps-api-docs.netlify.com/#googlemap
    return (
      <div className={p.className}>
        <SearchPanel>
          {this.state.showRoads && <RoadSearch auth={p.auth} onSelect={this.handleZoomToMBR}/>}
          {this.state.showStations && <StationSearch auth={p.auth} onSelect={this.handleZoomToMBR}/>}
        </SearchPanel>        
        <GoogleMap
          id='example-map'
          mapContainerStyle={{ height: "100%", width: "100%" }}
          zoom={8}
          onClick={this.handleClick}
          options={{
            mapTypeId: "hybrid",
            streetViewControl: false,
            fullscreenControl: false
          }}
          onLoad={this.handleMapLoaded}
        >
          {pos && <Marker 
            position={pos}
            icon={p.symbol}
            options={{clickable: false}}
          />}
          {pos2 && <Marker
            position={pos2}
            icon={p.symbol}
            options={{clickable: false}}
          />}
          {pos && pos2 && <Polyline 
            path={[pos, pos2]}
            options={{strokeColor: 'gold', clickable: false}}
          />}
        </GoogleMap>
        <StationsLayer selected={this.state.station} map={this.state.map} onClick={this.handleClickStation} visible={this.state.showStations}/>
        <RoadsLayer selected={this.state.road} map={this.state.map} onClick={this.handleClickRoad} visible={this.state.showRoads}/>
        {this.state.showRoads && <RoadPane road={this.state.road} onClose={this.handleClosePanels}/>}
        {this.state.showStations && <StationPane station={this.state.station} onClose={this.handleClosePanels}/>}
        <MapControls>
          <LayerToggle roads={this.state.showRoads} stations={this.state.showStations} susceptibility={this.state.showSusceptibility} onToggle={this.handleLayerToggle}/>
          {this.state.showSusceptibility && <SusceptibilityGradient/>}
        </MapControls>
      </div>
    )
  }
}

const SingleMarkerMap = styled(SingleMarkerMapBase)`
  position: relative;
  width: 100%;
  height: 60%;
`

export { SingleMarkerMap };