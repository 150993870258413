import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { IMBR, Region, RegionFactory } from '../resource';
import { Query, IAuthProps } from '../services';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

interface IProps {
  onSelect: (mbr: IMBR) => void;
}

interface IState {
  /** Data for regions dropdown */
  regions: Region[];
  region: Region;
}

class StationSearch extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      regions: [],
      region: null
    };    
  }

  componentDidMount() {
    // Retrieve a list of regions:
    RegionFactory.getSome(this.props.auth, 0, 5, new Query('name', 'asc'))
      .then((res) => this.setState({ regions: res.items }));    
  }

  handleRegionSearch = (q:string) => {
    // Retrieve a list of regions:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    RegionFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ regions: res.items }));
  }

  handleSelectRegion = (region: Region) => {
    this.setState({
      region: region
    });
    if(region != null) this.props.onSelect(region);
  }

  render() {
    return (
      <Form.Uncontrolled hint="Find station">
        <Dropdown onSearch={this.handleRegionSearch} name="station" fluid value={this.state.region} data={this.state.regions} placeholder="Station" label={(item:Region) => item.name } onChange={this.handleSelectRegion}>
          <Dropdown.Column weight={1}>{(item:Region) => item.name}</Dropdown.Column>
        </Dropdown>
      </Form.Uncontrolled>
    );
  }
}

export { StationSearch };
