import * as React from 'react';
import { Station } from '../../resource/';
import { IAuthProps } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Block } from '../../modules';


interface IProps {
  /** Initial form data. */
  data: Station;
  /** Called whenever form changes. */
  onChange: (data: Station, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Station;
}

class StationForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Flex.Columns count={2}>
          <Block title="Identification">
            <Form.Field 
              required={{message: "Name is required"}}
              minLength={{length: 3, message: "Station name must be at least 3 characters."}}
              maxLength={{length: 255, message: "Station name may not be longer than 255 characters."}}
              width={1} label="Station name"
              name="name" 
              value={this.state.data.name}
              control={(<Input type="text" placeholder="Station name" fluid/>)}
              hint={(<React.Fragment>Please enter a station name, e.g. <code>Zygoti</code></React.Fragment>)}/>
          </Block>
        </Flex.Columns>
      </Form>
    )
  }
}

export { StationForm };
