import { StampedModel, ResourceFactory } from '../services/';
import { format, parse } from 'date-fns';

class Landslide extends StampedModel {
  public station: {
    id: number;
    name: string;
  } = null;  

  public road: {
    id: number,
    road: string;
    linkid: string;
    link_name: string;
  } = null;

  date: string = "";
  rain: boolean = false;
  cause: string = "";
  surface: string = "";
  surface_other: string = "";
  type: string = "";
  location_from: string = "";
  location_to: string = "";
  location_km: string = "";
  latitude: string = "";
  longitude: string = "";
  length_total: string = "";

  // Origin
  origin: string = "";
  origin_inside_type: string = "";
  origin_wall: string = "";
  
  // Impact
  blockage: string = "";
  cracking: string = "";
  displacement: string = "";
  road_closed: boolean = false;
  road_closed_duration: string = "";
  road_closed_duration_unit: string = "hour";
  volume: string = "";
  reinstatement_description: string = "";
  reinstatement_cost: string = "";

  // Land use
  land_use_forest: boolean = false;
  land_use_dry_cultivation: boolean = false;
  land_use_irrigated_cultivation: boolean = false;
  land_use_other: boolean = false;
  land_use_other_description: string = "";
  land_use_change: string = "";
  land_use_change_description: string = "";
  drainage_condition: string = "";

  // Reinstatement
  funding_routine_maintenance: boolean = false;
  funding_emergency_works: boolean = false;
  funding_disaster_fund: boolean = false;
  funding_other: boolean = false;
  funding_other_description: string = "";

  // Other damage
  damage_walls: boolean = false;
  damage_walls_length: string = "";
  damage_walls_height: string = "";
  damage_walls_type: string = "";
  damage_culverts: boolean = false;
  damage_culverts_number: string = "";
  damage_culverts_type: string = "";
  damage_culverts_material: string = "";
  damage_culverts_size: number = 0;
  damage_bridges: boolean = false;
  damage_bridges_span: string = "";
  damage_bridges_type: string = '';
  damage_bridges_material: string = '';
  damage_pylons: boolean = false;
  damage_pylons_type: string = "";
  damage_cables: boolean = false;
  damage_cables_power: boolean = false;
  damage_cables_telephone: boolean = false;
  damage_cables_fibre: boolean = false;
  damage_cables_other: boolean = false;
  damage_cables_other_description: string = "";
  damage_buildings: boolean = false;
  damage_buildings_occupied: string = "";
  damage_buildings_unoccupied: string = "";
  damage_buildings_commercial: string = "";
  damage_buildings_category: string = "";
  damage_vehicular_accidents: boolean = false;
  damage_vehicular_accidents_number: string = "";
  damage_injuries: boolean = false;
  damage_injuries_number: string = "";
  damage_injuries_description: string = "";
  damage_fatalities: boolean = false;
  damage_fatalities_number: string = "";
  damage_other_description: string = "";
  imported: boolean = false;

  // Convert landslide type value to human-readable format.
  formatType = () => {
    if(!this.type) return null;
    return this.type.charAt(0).toUpperCase() + this.type.slice(1);
  }  

  // Convert landslide date value to human-readable format.
  formatDateLong = () => {
    if(!this.date) return null;
    return format(parse(this.date, 'yyyy-MM-dd', new Date()), 'E, d MMM yyyy');
  }

  formatDateLonger = () => {
    if(!this.date) return null;
    return format(parse(this.date, 'yyyy-MM-dd', new Date()), 'EEEE, d MMMM yyyy');
  }
  
}

let LandslideFactory = ResourceFactory.create<Landslide>(Landslide, 'landslide');

export { Landslide, LandslideFactory };