import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';

import { Container, Content, BottomBar, HelpPane } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { LandslideFactory, Landslide, IssueFactory, Issue } from '../../resource';
import { Query } from '../../services';
import { ManyMarkersMap } from '../../map';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Header } from '@independent-software/typeui/controls/Header';
import { Label } from '@independent-software/typeui/controls/Label';

interface IState {
  landslides: Landslide[];
  issues: Issue[];
  showHelp: boolean;
}

class Map extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      landslides: [],
      issues: [],
      showHelp: false
    }
  }

  componentDidMount() {
    let query = new Query('date', 'asc');
    LandslideFactory.getSome(this.props.auth, 0, 99999, query)
    .then(res => {
      this.setState({
        landslides: res.items
      });
    })
    .catch((error) => {
    });        

    query = new Query('type', 'asc');
    IssueFactory.getSome(this.props.auth, 0, 99999, query)
    .then(res => {
      this.setState({
        issues: res.items
      });
    })
    .catch((error) => {
    });            
  }

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>

          <ManyMarkersMap auth={p.auth} landslides={this.state.landslides} issues={this.state.issues}/>
          <HelpPane title="Map help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
            <p>
              This map shows all landslides and road reserve issues recorded in the database. 
            </p>
            <Header size="h3">Markers</Header>
            <p>
              Landslides and issues lying close 
              together may be shown as a <b>cluster</b>, coloured either <Label size="small" color="red">red</Label> (very many landslides/issues),
              <Label size="small" color="#FEBE00">yellow</Label> (many landslides/issues) or <Label size="small" color="#008CFF">blue</Label> (some landslides/issues). Individual landslides are shown
              as grey markers, and individual issues are shown as teal dots. You can click a cluster to zoom to its individual landslides 
              or subclusters. You can click on individual landslides and issues to view their information.
            </p>
            <Header size="h3">Navigation</Header>
            <p>
              You can pan the map by holding down the left mouse button and moving the mouse.
              You can zoom the map by clicking the <Icon name="plus"/> and <Icon name="minus"/> buttons,
              or using the mouse scrollwheel.
            </p>
            <Header size="h3">Road network</Header>
            <p>
              The UNRA road network is integrated into this map. You can click individual
              road sections to see road information. Station areas can also be visualized.
            </p>
            <Header size="h3">Searching and filtering</Header>
            <p>
              Use the <Icon name="search"/> button to filter the landslides and issues appearing on the map. 
              You can filter by landslide type, or by date. You can also choose to hide any landslides
              that were imported from other sources, and landslides that have no date.
            </p>
            <Header size="h3">Layers</Header>
            <p>
              The map can display layers for roads, stations, and susceptibility. These layers
              can be turned on or off individually using the panel at the bottom. The susceptibility
              map adjusts its resolution according to the map zoom level.
            </p>
          </HelpPane>

        </Content>
        <BottomBar>
          <div></div>
          <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>
        </BottomBar>
      </Container>
    );
  }
}

export { Map };
