import * as React from 'react';
import { Container, Content, BottomBar, Section, HelpPane } from '../../modules';
import { IssueForm } from './IssueForm';
import { IssueFactory, Issue } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Message } from '@independent-software/typeui/controls/Message';
import { Header } from '@independent-software/typeui/controls/Header';

type TStep = 'loading' | 'loadError' | 'ready' | 'saving' | 'saveError' | 'error';

interface IState {
  issue: Issue;
  isValid: boolean;
  step: TStep;
  error: any;
  dirty?: boolean;
  showHelp: boolean;
}

interface MatchParams {
  id: string;
}

class EditIssue extends React.Component<IAuthProps & RouteComponentProps<MatchParams>, IState> {
  private issue: Issue;

  constructor(props: IAuthProps & RouteComponentProps<MatchParams>) {
    super(props);

    this.state = {
      step: 'loading',
      isValid: false,
      error: null,
      issue: null,
      showHelp: false
    };
    
    this.loadItem();
  }

  loadItem() {
    IssueFactory.get(this.props.auth, parseInt(this.props.match.params.id)).then((issue) => {
      this.issue = issue;
      this.setState({
        step: 'ready',
        issue: this.issue
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  handleChange = (issue: Issue, forceupdate: boolean) => {
    this.issue = Object.assign(this.issue, issue);
    if(forceupdate) { 
      this.setState({ issue: this.issue });  
    }    
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  handleSubmit = () => {
    this.setState({ issue: this.issue, dirty: true });
    if(!this.state.isValid) return;    

    this.setState({ step: 'saving' });
    this.issue.$update(this.props.auth)
      .then(res => {
        ToastService.toast("Issue updated");
        this.props.history.goBack();
      })
      .catch(error => {
        this.setState({
          step: 'saveError',
          error: error
        })
      });
  } 

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleRetryLoad = () => {
    this.setState({ step: 'loading', error: null });
    this.loadItem();
  }
  
  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {(this.state.step == 'loading' || this.state.step == 'saving') && <Loader></Loader>}
        {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
        <React.Fragment>
          <Content>
            <IssueForm auth={p.auth} dirty={this.state.dirty} data={this.state.issue} onChange={this.handleChange} onValidate={this.handleValidate}/>
          </Content>
          <BottomBar>
            <Button primary onClick={this.handleSubmit}><Icon name="save"/> Save</Button>
            <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>
          </BottomBar>
        </React.Fragment>}
        {this.state.step == 'error' && <Content>
          <Section padded>
            <Message type="error">The requested data could not be retrieved from the server.</Message>
          </Section>
        </Content>}
        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetryLoad}/>
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave} onRetry={this.handleSubmit}/>
        <HelpPane title="Edit issue help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
          <p>This form is used to edit issue details.</p>
          <Header size="h3">What's here?</Header>
          <p>
            This form has fields for general data on the road reserve issue, as well as for data on location.
            Use the <b>tabs</b> at the top of the screen to navigate between
            different parts of the form.
          </p>
          <Header size="h3">Required data</Header>
          <p>
            While some form fields are optional, most fields are <b>mandatory</b>. These fields are marked with a
            red asterisk <span style={{color:'red'}}>*</span>. You can only save your changes if 
            you have completed all required fields.
          </p>
          <Header size="h3">Setting the issue location</Header>
          <p>
            This form offers a map to help you set the issue location. You can either type in the latitude
            and longitude in the appropriate fields, and the map will place a marker at that 
            location, <i>or</i> you can click the map at the right location and the fields will be filled out for you. 
            For convenience, the map offers road, station and susceptibility overlays that you can toggle on or off.
          </p>
          <Header size="h3">Saving data</Header>
          <p>
            When you have completed your changes, click the <b>Save</b> button to write the information
            to the database. If you have forgotten to fill out any required fields, the form will
            refuse to save. The missing fields will be highlighted in red. If you can't see the
            missing field, do not forget to look at the other tabs.
          </p>
        </HelpPane>        
      </Container>
    );
  }
}

export { EditIssue };
