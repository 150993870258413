import * as React from 'react';
import axios from 'axios';
import { App } from '../../App';
import { IMapFeature } from '../types/IMapFeature';

interface MapLayerProps<T> {
  map: google.maps.Map;
  onClick: (feature: T) => void;
  visible: boolean;  
  selected: T;
}

/**
 * A MapLayer is generic GIS data from the spatial database, shown on a
 * map as a Data layer.
 */
abstract class MapLayer<T extends IMapFeature> extends React.Component<MapLayerProps<T>, {}> {
  private data: google.maps.Data;

  componentDidUpdate = (prevProps: MapLayerProps<T>) => {
    // Initialize the Data layer only once the Google Map
    // is set.
    if(this.props.map != prevProps.map) {
      this.initialize();
    }
    // When the layer visibility is toggled, add or remoe the 
    // Data layer from the map. When the layer is set to 
    // visible, load data from the server.
    if(this.props.visible != prevProps.visible) {
      this.data.setMap(this.props.visible ? this.props.map : null);
      if(this.props.visible) this.loadData();
    }
    // When the seleted property changes, toggle the Data
    // layer's map attribute so that it gets redrawn.
    if(this.props.selected != prevProps.selected) {
      this.data.setMap(this.props.visible ? this.props.map : null);
    }
  }

  private initialize = () => {
    // Create a new Data layer:
    this.data = new google.maps.Data({ map: this.props.visible ? this.props.map : null });
    // Set styling for this Data layer.
    this.data.setStyle(this.dataStyle);
    // Set click listener for this Data layer.
    this.data.addListener('click', this.handleClickFeature);
    // Load roads when map becomes idle:
    this.props.map.addListener('idle', this.loadData);
  }

  /**
   * When a feature is clicked, let the parent map know, providing
   * it with the clicked feature.
   */
  private handleClickFeature = (e: google.maps.Data.MouseEvent) => {
    this.props.onClick(e.feature.getProperty('item'));
  }

  private loadData = () => {
    let map = this.data.getMap();
    if(!map) return;

    // Get bounds. If no bounds yet available, abort.
    let bounds = map.getBounds();
    if(!bounds) return;

    // Get zoom level.
    let zoom = map.getZoom();
    
    // Build Axios parameters:
    let params = {
      latSW: bounds.getSouthWest().lat(),
      latNE: bounds.getNorthEast().lat(),
      lngSW: bounds.getSouthWest().lng(),
      lngNE: bounds.getNorthEast().lng(),
      zoom: zoom
    }

    // Load data from the server.
    let data = this.data;
    axios.get(App.apiURL + this.getResource() + "/geojson", { params: params })
    .then(response => {
      let size = 0;
      // Remove all features
      data.forEach(function(feature) { data.remove(feature); });
      // Add new features
      response.data.forEach((item: T) => {
        size = size + item.g.length;
        let geo = `{"type": "FeatureCollection", "features": [ { "type": "Feature", "properties": { }, "geometry": ${item.g} } ] }`;
        let json = JSON.parse(geo);
        let { g, ...properties } = item;
        json.features[0].properties.item = properties;
        data.addGeoJson(json);
      });
      console.log(this.getResource() + " data: zoom ", zoom, " size ", Math.ceil(size / 1024), " KB");
    })
    .catch(error => {
    });
  }

  protected dataStyle = (feature: google.maps.Data.Feature): google.maps.Data.StyleOptions => {
    return this.getStyle(feature.getProperty('item'), this.props.map.getZoom());
  };

  // The resource if the data endpoint on the server.
  protected abstract getResource: () => string;

  protected abstract getStyle: (item: T, zoom: number) => google.maps.Data.StyleOptions;

  // Instance of MapLayer do not render anything.
  render = (): any => {
    return null;
  }  
}

export { MapLayer };