import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { Section, Viewer, Block, NA } from '../../modules';
import { SingleMarkerMap } from '../../map';
import { Landslide, LandslideFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { parse, format } from 'date-fns';

import { Flex } from '@independent-software/typeui/controls/Flex';
import { Header } from '@independent-software/typeui/controls/Header';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Table } from '@independent-software/typeui/controls/Table';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Button } from '@independent-software/typeui/controls/Button';
import { SYMBOL } from '../../map/Symbol';

class ViewLandslide extends React.Component<IAuthProps & RouteComponentProps<{id: string}>, {}> {
  
  private canEdit = (landslide: Landslide): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_landslides');
  }

  private handleEdit = () => {
    this.props.history.push(`/landslides/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Landslide deleted");
    this.props.history.goBack();    
  }

  private handleRoadClick = (id: number) => {
    this.props.history.push(`/roads/${id}`);
  }

  private handleStationClick = (id: number) => {
    this.props.history.push(`/stations/${id}`);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Landslide> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={LandslideFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(item: Landslide) => 
          <React.Fragment>
            <Section dark padded>
              {item.road &&
                <div style={{float: 'right'}}>
                  <Button secondary size='small' onClick={() => this.handleRoadClick(item.road.id)}>View road</Button>
                </div>}
              {item.station && p.auth && p.auth.hasRight('can_view_stations') &&
                <div style={{float: 'right'}}>
                  <Button secondary size='small' onClick={() => this.handleStationClick(item.station.id)}>View station</Button>
                </div>}
              <Header size="h3">
                <Icon title="date" url="sprites.svg#calendar-day"/> {item.date ? item.formatDateLonger() : <NA/>}
              </Header>
              {item.road &&
              <Header size="h3">
                <Icon title="road" url="sprites.svg#road"/> {item.road.linkid}
                {item.station && <React.Fragment>&nbsp;&nbsp;<Icon title="station" url="sprites.svg#hard-hat"/> {item.station.name}</React.Fragment>}
              </Header>}
            </Section>
            <Tabs underlined>
              <Tabs.Pane label="General">
                <Stretch>
                  <StretchInner>
                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Type">
                            {!item.type && "Unknown type"}
                            {item.type == 'new' && <React.Fragment>A <b>new</b> landslide</React.Fragment>}
                            {item.type == 'reactivation' && <React.Fragment><b>Reactivation</b> of an existing landslide</React.Fragment>}
                            {item.type == 'extension' && <React.Fragment><b>Extension</b> of an existing landslide</React.Fragment>}
                          </Block>

                          <Block title="Rain">
                            <Checkmark value={item.rain}/> Did the landslide occur during or after heavy rain?
                          </Block>

                          <Block title="Surface">
                            {!item.surface && "Unknown surface"}
                            {item.surface == 'asphalt' && <React.Fragment>Asphalt</React.Fragment>}
                            {item.surface == 'gravel' && <React.Fragment>Gravel</React.Fragment>}
                            {item.surface == 'other' && <React.Fragment><i>Other:</i> {item.surface_other}</React.Fragment>}
                          </Block>

                        </div>
                        <div>
                          <Block title="Causes">
                            <Markdown source={item.cause}/>
                          </Block>
                        </div>
                        
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Location">
                <Stretch>
                  
                  <StretchInner>

                  <SingleMarkerMap symbol={SYMBOL} auth={p.auth} lat={parseFloat(item.latitude)} lng={parseFloat(item.longitude)}/>

                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Road">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Road code</td>
                                  <td style={{textAlign: 'right'}}>{item.road ? item.road.road : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>Road link</td>
                                  <td style={{textAlign: 'right'}}>{item.road ? item.road.linkid : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>Road link name</td>
                                  <td style={{textAlign: 'right'}}>{item.road ? item.road.link_name : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>From town</td>
                                  <td style={{textAlign: 'right'}}>{item.location_from ? item.location_from : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>To town</td>
                                  <td style={{textAlign: 'right'}}>{item.location_to ? item.location_to : <NA/>}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>
                        </div>
                        <div>
                          <Block title="Location">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Distance from start town</td>
                                  <td style={{textAlign: 'right'}}>{item.location_km ? <React.Fragment><Number value={item.location_km} decimals={2}/> km</React.Fragment> : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>Road length affected</td>
                                  <td style={{textAlign: 'right'}}>{item.length_total ? <React.Fragment><Number value={item.length_total} decimals={2}/> m</React.Fragment> : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>Latitude</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.latitude} decimals={6}/></td>
                                </tr>
                                <tr>
                                  <td>Longitude</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.longitude} decimals={6}/></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>

                        </div>
                        
                      </Flex.Columns>                
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Origin">
                <Stretch>
                  <StretchInner>
                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Origin">
                            <Question>The landslide originated:</Question>
                            {!item.origin && "Unknown origin"}
                            {item.origin == 'inside' && <React.Fragment>Inside the Road Reserve</React.Fragment>}
                            {item.origin == 'above' && <React.Fragment>From the natural slopes <b>above</b> the Road Reserve</React.Fragment>}
                            {item.origin == 'below' && <React.Fragment>From the natural slopes <b>below</b> the Road Reserve</React.Fragment>}
                          </Block>

                          {item.origin == 'inside' && <Block title="Failure">
                            <Question>It was a failure of:</Question>
                            {item.origin_inside_type == 'cut' && <React.Fragment>Cut slope</React.Fragment>}
                            {item.origin_inside_type == 'fill' && <React.Fragment>Fill slope</React.Fragment>}
                            {item.origin_inside_type == 'wall' && <React.Fragment>Retaining wall</React.Fragment>}
                          </Block>}

                          {item.origin_inside_type == 'wall' && <Block title="Retaining wall">
                            <Question>The retaining wall was:</Question>
                            {item.origin_wall == 'above' && <React.Fragment>Above</React.Fragment>}
                            {item.origin_wall == 'below' && <React.Fragment>Below</React.Fragment>}
                          </Block>}
                        </div>
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Impact">
                <Stretch>
                  <StretchInner>
                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Blockage">
                            <Question>Was there blockage of any part of the road?</Question>
                            {!item.blockage && "Unknown"}
                            {item.blockage == 'none' && <React.Fragment>No blockage</React.Fragment>}
                            {item.blockage == 'drain' && <React.Fragment>Blockage to side drain</React.Fragment>}
                            {item.blockage == 'carriageway' && <React.Fragment>Blockage to side drain and inside carriageway</React.Fragment>}
                            {item.blockage == 'road' && <React.Fragment>Blockage to entire road</React.Fragment>}
                          </Block>

                          <Block title="Cracking">
                            <Question>Was there cracking of any part of the road?</Question>
                            {!item.cracking && "Unknown"}
                            {item.cracking == 'none' && <React.Fragment>No cracking</React.Fragment>}
                            {item.cracking == 'shoulder' && <React.Fragment>Cracking to outside shoulder</React.Fragment>}
                            {item.cracking == 'carriageway' && <React.Fragment>Cracking to shoulder and outside carriageway</React.Fragment>}
                            {item.cracking == 'road' && <React.Fragment>Cracking to entire road</React.Fragment>}
                          </Block>    

                          <Block title="Displacement">
                            <Question>Was there displacement of any part of the road?</Question>
                            {!item.displacement && "Unknown"}
                            {item.cracking == 'none' && <React.Fragment>No displacement</React.Fragment>}
                            {item.cracking == 'carriageway' && <React.Fragment>Displacement of outside carriageway</React.Fragment>}
                            {item.cracking == 'road' && <React.Fragment>Displacement of entire road</React.Fragment>}
                          </Block>    

                        </div>
                        <div>

                          <Block title="Road closure">
                            <Checkmark value={item.road_closed}/> Did the road have to be closed?
                            {item.road_closed == true && <div>
                              Duration: <Number value={item.road_closed_duration} decimals={2}/> {item.road_closed_duration_unit == 'hour' ? "hours" : "days"}
                            </div>}
                          </Block>                         

                          <Block title="Volume">
                            <Question>Volume of the landslide</Question>
                            {!item.volume && "Unknown"}
                            {!!item.volume && <React.Fragment><Number value={item.volume} decimals={0}/> m<sup>3</sup></React.Fragment>}
                          </Block>     

                          <Block title="Reinstatement">
                            <Question>How was the road reinstated/how will it be reinstated?</Question>
                            <Markdown source={item.reinstatement_description}/>
                          </Block>    

                          <Block title="Reinstatement cost">
                            {!item.reinstatement_cost && <React.Fragment>Not provided</React.Fragment>}
                            {parseFloat(item.reinstatement_cost) > 0 && <React.Fragment><Number value={item.reinstatement_cost} decimals={2}/> UGX</React.Fragment>}
                          </Block>           

                        </div>
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Land use">
                <Stretch>
                  <StretchInner>
                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Land use">
                            <Question>What was the land use in the source area of the landslide?</Question>
                            <div><Checkmark value={item.land_use_forest}/> Forest</div>
                            <div><Checkmark value={item.land_use_dry_cultivation}/> Dry cultivation</div>
                            <div><Checkmark value={item.land_use_irrigated_cultivation}/> Irrigated cultivation</div>
                            <div><Checkmark value={item.land_use_other}/> Other</div>
                            {item.land_use_other == true && <Markdown source={item.land_use_other_description}/>}
                          </Block>

                          <Block title="Land use change">
                            <Question>Has the land use changed in recent months?</Question>
                            {item.land_use_change == 'no' && <React.Fragment><Checkmark value={false}/> The land use has not changed</React.Fragment>}
                            {(!item.land_use_change || item.land_use_change == 'unknown') && <React.Fragment><Checkmark value={false}/> Unknown</React.Fragment>}
                            {item.land_use_change == 'yes' && 
                            <React.Fragment>
                              <Checkmark value={true}/> The land use has changed:
                              <Markdown source={item.land_use_change_description}/>
                            </React.Fragment>}
                          </Block>                      

                        </div>
                        <div>
                          <Block title="Drainage">
                            <Question>What is the drainage condition of the slope?</Question>
                            {!item.drainage_condition && "Unknown"}
                            {item.drainage_condition == 'wet' && <React.Fragment>Wet with springs and seepage</React.Fragment>}
                            {item.drainage_condition == 'dry' && <React.Fragment>Dry</React.Fragment>}
                            {item.drainage_condition == 'intermittent' && <React.Fragment>Intermittent dry and wet</React.Fragment>}
                          </Block>
                        </div>
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Reinstatement">
                <Stretch>
                  <StretchInner>
                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Reinstatement funding">
                            <Question>How will the reinstatement works be funded / how were the reinstatement works funded?</Question>
                            <div><Checkmark value={item.funding_routine_maintenance}/> Routine maintenance</div>
                            <div><Checkmark value={item.funding_emergency_works}/> Emergency works</div>
                            <div><Checkmark value={item.funding_disaster_fund}/> Disaster fund</div>
                            <div><Checkmark value={item.funding_other}/> Other</div>
                            {item.funding_other == true && <Markdown source={item.funding_other_description}/>}
                          </Block>

                        </div>
                        <div>
                        </div>
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Other damage">
                <Stretch>
                  <StretchInner>
                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          {item.damage_walls == true && <Block title="Retaining walls">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Length of damaged wall</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_walls_length} decimals={2}/> m</td>
                                </tr>
                                <tr>
                                  <td>Height of damaged wall</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_walls_height} decimals={2}/> m</td>
                                </tr>
                                <tr>
                                  <td>Type of damaged wall</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_walls_type == 'gabion' && <React.Fragment>Gabion</React.Fragment>}
                                    {item.damage_walls_type == 'masonry' && <React.Fragment>Masonry</React.Fragment>}
                                    {item.damage_walls_type == 'concrete' && <React.Fragment>Reinforced concrete</React.Fragment>}
                                    {item.damage_walls_type == 'other' && <React.Fragment>Other</React.Fragment>}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>}

                          {item.damage_culverts == true && <Block title="Culverts">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Culverts</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_culverts_number} decimals={0}/></td>
                                </tr>
                                <tr>
                                  <td>Culvert type</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_culverts_type == 'pipe' && <React.Fragment>Pipe</React.Fragment>}
                                    {item.damage_culverts_type == 'box' && <React.Fragment>Box</React.Fragment>}
                                    {item.damage_culverts_type == 'arch' && <React.Fragment>Pipe arch</React.Fragment>}
                                    {item.damage_culverts_type == 'bridge' && <React.Fragment>Bridge</React.Fragment>}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Culvert material</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_culverts_material == 'concrete' && <React.Fragment>Concrete</React.Fragment>}
                                    {item.damage_culverts_material == 'steel' && <React.Fragment>Steel</React.Fragment>}
                                    {item.damage_culverts_material == 'plastic' && <React.Fragment>Plastic</React.Fragment>}
                                    {item.damage_culverts_material == 'composite' && <React.Fragment>Composite</React.Fragment>}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Culvert size</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_culverts_size == 300 && <React.Fragment>300mm</React.Fragment>}
                                    {item.damage_culverts_size == 450 && <React.Fragment>450mm</React.Fragment>}
                                    {item.damage_culverts_size == 600 && <React.Fragment>600mm</React.Fragment>}
                                    {item.damage_culverts_size == 900 && <React.Fragment>900mm</React.Fragment>}
                                    {item.damage_culverts_size == 1200 && <React.Fragment>1200mm</React.Fragment>}
                                    {item.damage_culverts_size == 1800 && <React.Fragment>1800mm</React.Fragment>}
                                    {item.damage_culverts_size == 2100 && <React.Fragment>2100mm</React.Fragment>}
                                  </td>
                                </tr>

                              </tbody>
                            </Table>
                          </Block>}

                          {item.damage_bridges == true && <Block title="Bridges">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td style={{width:'75%'}}>Total damaged bridge span</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_bridges_span} decimals={2}/> m</td>
                                </tr>
                                <tr>
                                  <td>Bridge type</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_bridges_type == 'beam' && <React.Fragment>Beam</React.Fragment>}
                                    {item.damage_bridges_type == 'arch' && <React.Fragment>Arch</React.Fragment>}
                                    {item.damage_bridges_type == 'cantilever' && <React.Fragment>Cantilever</React.Fragment>}
                                    {item.damage_bridges_type == 'suspension' && <React.Fragment>Suspension</React.Fragment>}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bridge material</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_bridges_material == 'wood' && <React.Fragment>Wood</React.Fragment>}
                                    {item.damage_bridges_material == 'steel' && <React.Fragment>Steel</React.Fragment>}
                                    {item.damage_bridges_material == 'concrete' && <React.Fragment>Concrete</React.Fragment>}
                                    {item.damage_bridges_material == 'composite' && <React.Fragment>Composite</React.Fragment>}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>}

                          {item.damage_pylons == true && <Block title="Pylons">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Type of pylons</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_pylons_type == 'steel' && <React.Fragment>Steel</React.Fragment>}
                                    {item.damage_pylons_type == 'wood' && <React.Fragment>Wood</React.Fragment>}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>}

                          {item.damage_cables == true && <Block title="Cables">
                            <div><Checkmark value={item.damage_cables_power}/> Power cables</div>
                            <div><Checkmark value={item.damage_cables_telephone}/> Telephone cables</div>
                            <div><Checkmark value={item.damage_cables_fibre}/> Fibre-optic cables</div>
                            <div><Checkmark value={item.damage_cables_other}/> Other cables: {item.damage_cables_other_description}</div>
                          </Block>}

                        </div>
                        <div>

                          {item.damage_buildings == true && <Block title="Buildings">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td style={{width:'75%'}}>Occupied buildings</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_buildings_occupied} decimals={0}/></td>
                                </tr>
                                <tr>
                                  <td style={{width:'75%'}}>Unoccupied buildings</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_buildings_unoccupied} decimals={0}/></td>
                                </tr>
                                <tr>
                                  <td style={{width:'75%'}}>Commercial buildings</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_buildings_commercial} decimals={0}/></td>
                                </tr>
                                <tr>
                                  <td>Destruction of property</td>
                                  <td style={{textAlign: 'right'}}>
                                    {item.damage_buildings_category == 'total' && <React.Fragment>Total</React.Fragment>}
                                    {item.damage_buildings_category == 'partial' && <React.Fragment>Partial</React.Fragment>}
                                    {item.damage_buildings_category == 'minimal' && <React.Fragment>Minimal</React.Fragment>}
                                  </td>
                                </tr>                                
                              </tbody>
                            </Table>
                          </Block>}

                          {item.damage_vehicular_accidents == true && <Block title="Vehicular accidents">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Vehicles involved</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_vehicular_accidents_number} decimals={0}/></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>}

                          {item.damage_injuries == true && <Block title="Injuries">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>People injured</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_injuries_number} decimals={0}/></td>
                                </tr>
                              </tbody>
                            </Table>
                            <div><b>Description of injuries:</b></div>
                            <Markdown source={item.damage_injuries_description}/>
                          </Block>}

                          {item.damage_fatalities == true && <Block title="Fatalities">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Fatalities</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.damage_fatalities_number} decimals={0}/></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>}

                          {item.damage_other_description && <Block title="Other damage">
                            <Markdown source={item.damage_other_description}/>
                          </Block>}

                        </div>
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>              

            </Tabs>

          </React.Fragment>}>
      </Viewer>
    );
  }
}

const Question = styled('div')`
  font-style: italic;
  color: #888;
  margin-bottom: 5px;
`

const Stretch = styled('div')`
  position: relative;
  height: 100%;
  margin-top: -14px;
`

const StretchInner = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -14px;
  overflow-y: scroll;
`

class Checkmark extends React.Component<{value: boolean}, {}> {
  render() {
    return (
      <Icon 
        name={this.props.value ? 'check' : 'times'}
        color={this.props.value ? 'green' : 'darkred'}
      />
    )
  }
}


export { ViewLandslide };
