import * as React from 'react';
import { Container, Content, BottomBar, Section, Timestamp } from '../../modules';
import { IAuthProps } from '../../services/Auth';
import { Resource, StampedModel } from '../../services';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Message } from '@independent-software/typeui/controls/Message';

type TStep = 'loading' | 'loadError' | 'ready' | 'confirm' | 'deleting' | 'deleteError' | 'error';

interface IViewerProps<T> {
  className?: string;
  /** Unique ID of resource to load using Factory. */
  id: number;
  /** Factory to use to load resource. */
  factory: Resource<T>;
  /** Given a resource instance, this callback determines if it can be edited. */
  canEdit: (item: any) => boolean;
  /** Callback is called when editing is requested. */
  onEdit: () => void;
  /** Callback is called after deletion. */
  onDelete: () => void;
  /** Callback is called with a resource instance. It should return a view template. */
  content: (item: any) => React.ReactNode;
  /** Callback is called with a resource instance. It should return any controls
   *  to be shown in the bottom bar. */
  controls?: (item: any) => React.ReactNode;
}

interface IViewerState {
  item: StampedModel;
  step: TStep;
  error: any;
}

class Viewer<T extends StampedModel> extends React.Component<IViewerProps<T> & IAuthProps, IViewerState> {
  constructor(props: IViewerProps<T> & IAuthProps) {
    super(props);
    this.state = {
      item: null,
      step: 'loading',
      error: null
    };
    this.loadItem();
  }

  private loadItem = () => {
    this.props.factory.get(this.props.auth, this.props.id)
    .then((item) => {
      this.setState({
        step: 'ready',
        item: item
      });
    })
    .catch(error => {
      this.setState({
        step: 'loadError',
        error: error
      })
    });
  }

  private goto = (step:TStep) => {
    this.setState({ step: step});
  }  

  private handleEdit = () => {
    this.props.onEdit();
  }

  private handleCancelLoad = () => {
    this.setState({ step: 'error' });
  }

  private handleRetry = () => {
    this.setState({error: null, step: 'loading'});
    this.loadItem();
  }  

  private handleDelete = () => {
    this.setState({ step: 'deleting', error: null});
    this.state.item.$delete(this.props.auth)
      .then(res => {
        this.props.onDelete();
      })
      .catch(error => {
        this.setState({
          step: 'deleteError',
          error: error
        })
      })
  }      

  render() {
    let p = this.props;
    return (
      <Container>
        {(this.state.step == 'loading' || this.state.step == 'loadError' || this.state.step == 'deleting') && 
        <Loader/>}
        {this.state.step != 'loading' && this.state.step != 'loadError' && this.state.step != 'error' && 
        <React.Fragment>
          <Content>
            {p.content(this.state.item)}
          </Content>
          <BottomBar>
            <div>
              {p.canEdit(this.state.item) && <Button primary onClick={this.handleEdit}><Icon name="edit"/> Edit</Button>}
              {p.controls && p.controls(this.state.item)}
            </div>
            <div>
              {p.canEdit(this.state.item) && <Button negative onClick={() => this.goto('confirm')}><Icon name="trash"/> Delete</Button>}
              <Timestamp model={this.state.item}/>
            </div>
          </BottomBar>
        </React.Fragment>}
        {this.state.step == 'error' && 
        <Content>
          <Section padded>
            <Message type="error">The requested data could not be retrieved from the server.</Message>
          </Section>
        </Content>}        
        <Dialog.Xhr open={this.state.step == 'loadError'} error={this.state.error} onClose={this.handleCancelLoad} onRetry={this.handleRetry}/>    
        <Dialog.Xhr open={this.state.step == 'deleteError'} error={this.state.error} onClose={() => this.goto('ready')} onRetry={this.handleDelete}/>
        <Dialog.Confirm open={this.state.step == 'confirm'} onClose={() => this.goto('ready')} onConfirm={this.handleDelete}>
          Are you sure you wish to delete this record permanently from the database?
        </Dialog.Confirm>

      </Container>
    );
  }  
}

export { Viewer };

