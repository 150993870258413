const BaseMapStyle: google.maps.MapTypeStyle[] = 
[
  {
    "featureType": "road",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

export { BaseMapStyle };