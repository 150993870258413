import { StampedModel, ResourceFactory } from '../services/';

class Station extends StampedModel {
  public name: string = '';
  public users_count: number = 0;
  public landslides_count: number = 0;
  public issues_count: number = 0;
}

let StationFactory = ResourceFactory.create<Station>(Station, 'station');

export { Station, StationFactory };