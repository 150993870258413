import { StampedModel, ResourceFactory } from '../services/';

class Issue extends StampedModel {
  public station: {
    id: number;
    name: string;
  } = null;  
  
  public road: {
    id: number,
    road: string;
    linkid: string;
    link_name: string;
  } = null;

  title: string;
  description: string;

  chainage_start: string;
  latitude_start: string;
  longitude_start: string;

  chainage_end: string;
  latitude_end: string;
  longitude_end: string;

  type: string = '';

  // Convert issue type value to human-readable format.
  formatType = () => {
    if(!this.type) return null;
    return this.type.charAt(0).toUpperCase() + this.type.slice(1).replace(/_/g, ' ');
  }    
}

let IssueFactory = ResourceFactory.create<Issue>(Issue, 'issue');

export { Issue, IssueFactory };