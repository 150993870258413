import * as React from 'react';
import { Issue, IssueFactory, Station, StationFactory, Road, RoadFactory } from '../../resource/'; 
import { List, IListState, IListProps, Query } from '../../services/';
import { IconBar, Export, NA } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Input } from '@independent-software/typeui/controls/Input';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IListIssuesProps extends IListProps {
  /**
   * If set, list is prefiltered by a station and
   * station column and filter are hidden.
   */
  stationId?: number;  
  /**
   * If set, list if prefiltered by a road and 
   * road column and filter are hidden.
   */
  roadId?: number;
  /**
   * Event is fired when an issue is clicked.
   */
  onClick?: (issue:Issue) => void;
}

interface IListIssuesState extends IListState<Issue> {
  stations: Station[],
  roads: Road[]
}

class ListIssues extends List<Issue, IListIssuesProps, IListIssuesState> {
  constructor(props: IListIssuesProps) {
    super(props, IssueFactory, 'type', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      stations: [],
      roads: []
    };

    if(this.props.stationId) {
      this.setFilter('station', 'eq', { id: this.props.stationId }, true);
    }

    if(this.props.roadId) {
      this.setFilter('road', 'eq', { id: this.props.roadId }, true);
    }
  }

  componentDidMount() {
    if(!this.props.stationId) this.handleStationSearch();    
    if(!this.props.roadId) this.handleRoadSearch();
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }

  handleChangeStation = (value: Station) => {
    this.setFilter('station', 'eq', value);
  }

  handleStationSearch = (q?:string) => {
    // Retrieve a list of stations:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    StationFactory.getSome(this.props.auth, 0, 999, query)
      .then((res) => this.setState({ stations: res.items }));
  }

  handleChangeRoad = (value: Road) => {
    this.setFilter('road', 'eq', value);
  }

  handleRoadSearch = (q?:string) => {
    // Retrieve a list of roads:
    let query = new Query('linkid', 'asc');
    if (q) query.setFilter('q', 'like', q);
    RoadFactory.getSome(this.props.auth, 0, 12, query)
      .then((res) => this.setState({ roads: res.items }));
  }  

  handleChangeType = (value: string) => {
    this.setFilter('type', 'eq', value);
  }

  formatLandslideType = (type: string) => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        {!p.stationId && 
          <Form.Uncontrolled hint="Filter by station">
            <Dropdown onSearch={this.handleStationSearch} name="station" fluid clearable value={this.getFilter('station', 'eq')} data={this.state.stations} placeholder="Station" label={(item:Station) => item.name} onChange={this.handleChangeStation}>
              <Dropdown.Column weight={3}>{(item:Station) => item.name}</Dropdown.Column>
              <Dropdown.Column align='right'>{(item:Station) => item.issues_count}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}        
        {!p.roadId && 
          <Form.Uncontrolled hint="Filter by road">
            <Dropdown onSearch={this.handleRoadSearch} name="road" fluid clearable value={this.getFilter('road', 'eq')} data={this.state.roads} placeholder="Road" label={(item:Road) => <span>{item.road} &mdash; {item.linkid} &mdash; {item.link_name}</span>} onChange={this.handleChangeRoad}>
              <Dropdown.Column weight={1}>{(item:Road) => item.road}</Dropdown.Column>
              <Dropdown.Column weight={2}>{(item:Road) => item.linkid}</Dropdown.Column>
              <Dropdown.Column weight={4}>{(item:Road) => item.link_name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}
        <Form.Uncontrolled hint="Filter by type">
          <Dropdown name="type" fluid clearable value={this.getFilter('type', 'eq')} placeholder="Type" 
            data={['new', 'reactivation', 'extension']}
            label={this.formatLandslideType}
            onChange={this.handleChangeType}>
            <Dropdown.Column>{this.formatLandslideType}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>
    
    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon={{name:'tools', title: 'Filter', inverted: true, cornered: true, bordered: true}} width={600}>
            {filter}
          </Panel.Icon>
        </IconBar>           
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          {!p.stationId && <DataTable.Column force weight={3} label="Station">{(item:Issue) => item.station ? item.station.name : <NA/>}</DataTable.Column>}
          {!p.roadId && <DataTable.Column force weight={3} label="Road">{(item:Issue) => item.road ? item.road.road : <NA/>}</DataTable.Column>}
          {!p.roadId && <DataTable.Column force weight={3} label="Link">{(item:Issue) => item.road ? item.road.linkid : <NA/>}</DataTable.Column>}
          <DataTable.Column weight={3} label="Title" order="title" dir="asc">{(item:Issue) => item.title}</DataTable.Column>
          <DataTable.Column weight={2} label="Type" order="type" dir="asc">{(item:Issue) => item.type ? item.formatType() : <NA/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListIssues };
