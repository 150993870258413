import * as React from 'react';
import { Section, Viewer } from '../../modules';
import { Profile, ProfileFactory, User } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { ListUsers } from '../User/ListUsers';
import { IAuthProps } from '../../services/Auth';
import { accessGroups, IAccessGroup, IRight } from '../../config/Config';
import { ValueStore } from '../../services';

import { Icon } from '@independent-software/typeui/controls/Icon';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Label } from '@independent-software/typeui/controls/Label';
import { ToastService } from '@independent-software/typeui/controls/Toast';

class ViewProfile extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (profile: Profile): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_profiles');
  }

  private handleEdit = () => {
    this.props.history.push(`/profiles/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Profile deleted");
    this.props.history.goBack();    
  }  

  private handleUserClick = (item:User) => {
    this.props.history.push(`/users/${item.id}`);
  }  

  private handleTabChange = (index: number) => {
    ValueStore.set("view_profile", index);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Profile> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={ProfileFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(profile: Profile) => 
          <React.Fragment>
            <Section dark padded>
              <Header size="h3">{profile.name}</Header>
            </Section>
            <Tabs underlined active={ValueStore.get('view_profile', 0)} onTabChange={this.handleTabChange}>
              <Tabs.Pane label="Data">
                <Section padded>
                  <Flex.Columns count={2}>
                    {accessGroups.map((item, idx) => <AccessGroup key={idx} auth={p.auth} profile={profile} group={item}/>)}
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span>Users <Label>{profile.users_count}</Label></span>}>
                <ListUsers auth={p.auth} name="profile_users" profileId={profile.id} onClick={this.handleUserClick}/>
              </Tabs.Pane>                
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

class AccessGroup extends React.Component<IAuthProps & { group: IAccessGroup, profile: Profile }, {}> {
  render() {
    let p = this.props;

    // Does this group have any active rights?
    let hasActiveRights = p.group.rights.some((r: IRight) => (p.profile as any)[r.name] == 1);

    return (
      <React.Fragment>
        <Segment secondary raised attached="top" disabled={!hasActiveRights}>
          <b>{p.group.title}</b>
        </Segment>
        <Segment raised attached="bottom" disabled={!hasActiveRights}>
          {p.group.rights.map((right, index) => 
            <Right key={index} noActive={!hasActiveRights} auth={p.auth} right={right} profile={p.profile}/>
          )}
        </Segment>        
      </React.Fragment>
    );
  }
}

class Right extends React.Component<IAuthProps & { noActive: boolean, right: IRight, profile: Profile }, {}> {
  render() {
    let p = this.props;
    let hasRight = (p.profile as any)[p.right.name] == 1;
    return (
      <div style={{display:'flex', alignItems: 'center'}}>
        {hasRight && 
        <React.Fragment>
          <Icon name="check"/>&nbsp;&nbsp;{p.right.title}
        </React.Fragment>}
        {!hasRight && 
        <React.Fragment>
          <Icon color={!p.noActive ? '#aaa' : ''} name="times"/>&nbsp;&nbsp;<span style={{color: p.noActive ? '' : '#aaa'}}>{p.right.title}</span>
        </React.Fragment>}
      </div>
    );
  }
}

export { ViewProfile };
