import * as React from 'react';
import { Profile, ProfileFactory } from '../../resource/'; 
import { List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IListProfilesProps extends IListProps {
  /**
   * Event is fired when a profile is clicked.
   */
  onClick?: (profile:Profile) => void;
}

interface IListProfilesState extends IListState<Profile> {
}

class ListProfiles extends List<Profile, IListProfilesProps, IListProfilesState> {
  constructor(props: IListProfilesProps) {
    super(props, ProfileFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }

  handleChangeFilterEmpty = (value: boolean) => {
    this.setFilter('users', 'eq', value ? 0 : null);
  }  

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="">
          <Checkbox label="Only empty profiles" name='empty' type="toggle" checked={this.getFilter('users', 'eq') != null} onChange={this.handleChangeFilterEmpty}/>
        </Form.Uncontrolled>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>
    
    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon={{name:'tools', title: 'Filter', inverted: true, cornered: true, bordered: true}} width={300}>
            {filter}
          </Panel.Icon>
        </IconBar>           
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={3} label="Name" order="name" dir="asc">{(item:Profile) => item.name}</DataTable.Column>
          <DataTable.Column force align="right" weight={1} label="Users" order="users_count" dir="desc">{(item:Profile) => item.users_count}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListProfiles };
