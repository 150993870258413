import * as React from 'react';
import { Section, Viewer } from '../../modules';
import { User, UserFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { ListLogs } from '../Log/ListLogs';

import { Icon } from '@independent-software/typeui/controls/Icon';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Gravatar } from '@independent-software/typeui/controls/Gravatar';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { formatDistanceToNow, parse } from 'date-fns/esm';
import { Label } from '@independent-software/typeui/controls/Label';

class ViewUser extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (user: User): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_users');
  }  

  private handleEdit = () => {
    this.props.history.push(`/users/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("User deleted");
    this.props.history.goBack();        
  }    

  render() {
    let p = this.props;
    return (
      <Viewer<User> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={UserFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(user: User) => 
          <React.Fragment>
            <Section dark padded>
              <Flex>
                <Flex.Row>
                  <Flex.Column>
                    <Gravatar email={user.email} size="tiny"/>
                  </Flex.Column>
                  <Flex.Column width={1}>
                    <Label><Icon name="id-card" color="#888"/> {user.name}</Label>
                    <Label><Icon name="envelope" color="#888"/> {user.email}</Label>
                    <Label>{user.profile.name}</Label>
                    {user.station && <Label>{user.station.name}</Label>}
                    <Header size="h5">Last seen: {user.login_at ? (formatDistanceToNow(parse(user.login_at.toString(), 'yyyy-MM-dd HH:mm:ss', new Date())) + ' ago') : 'never'}</Header>
                  </Flex.Column>
                </Flex.Row>
              </Flex>
            </Section>
            <Tabs underlined>
              <Tabs.Pane label="Log">
                <ListLogs auth={p.auth} name="user_logs" userId={user.id}/>
              </Tabs.Pane>
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewUser };
