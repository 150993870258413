import * as React from 'react';
import { Section, Container, Content, BottomBar, Block, HelpPane } from '../../modules';
import { Road, RoadFactory, Landslide, Issue } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Icon } from '@independent-software/typeui/controls/Icon';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Gravatar } from '@independent-software/typeui/controls/Gravatar';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Label } from '@independent-software/typeui/controls/Label';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Table } from '@independent-software/typeui/controls/Table';
import { Number } from '@independent-software/typeui/formatters/Number';
import { ListLandslides } from '../Landslide';
import { Button } from '@independent-software/typeui/controls/Button';
import { ListIssues } from '../Issue/ListIssues';

interface IState {
  road: Road;
  showHelp: boolean;
}

class ViewRoad extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {
  private id: number;

  constructor(props: IAuthProps & RouteComponentProps<{id: string}>) {
    super(props);
    this.state = {
      road: null,
      showHelp: false
    };
  }

  componentDidMount() {
    this.id = parseInt(this.props.match.params.id);
    this.loadItem();
  }

  private loadItem = () => {
    RoadFactory.get(this.props.auth, this.id)
    .then((item) => {
      this.setState({
        road: item
      });
    })
    .catch(error => {
    });
  }

  private handleLandslideClick = (item:Landslide) => {
    this.props.history.push(`/landslides/${item.id}`);
  }  

  private handleIssueClick = (item:Issue) => {
    this.props.history.push(`/issues/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          {this.state.road != null &&
          <React.Fragment>
            <Section dark padded>
              {this.state.road.road} / {this.state.road.linkid} / {this.state.road.link_name}
            </Section>
            <Tabs underlined>
              <Tabs.Pane label="Data">
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Identification">
                        <Table transparent striped>
                          <tbody>
                            <tr>
                              <td>Road</td><td>{this.state.road.road}</td>
                            </tr>
                            <tr>
                              <td>Link ID</td><td>{this.state.road.linkid}</td>
                            </tr>
                            <tr>
                              <td>Link name</td><td>{this.state.road.link_name}</td>
                            </tr>
                            <tr>
                              <td>Station</td><td>{this.state.road.station}</td>
                            </tr>
                            <tr>
                              <td>Region</td><td>{this.state.road.region}</td>
                            </tr>                            
                          </tbody>
                        </Table>
                      </Block>
                    </div>
                    <div>
                      <Block title="Class">
                        <Table transparent striped>
                          <tbody>
                            <tr>
                              <td>Class</td><td>{this.state.road.class}</td>
                            </tr>
                            <tr>
                              <td>Surface</td><td>{this.state.road.surface_cl}</td>
                            </tr>                            
                          </tbody>
                        </Table>
                      </Block>
                      <Block title="Length">
                        <Table transparent striped>
                          <tbody>
                            <tr>
                              <td>Length</td><td style={{textAlign: 'right'}}><Number value={this.state.road.l_km} decimals={2}/> km</td>
                            </tr>
                            <tr>
                              <td>Start</td><td style={{textAlign: 'right'}}><Number value={this.state.road.start_km} decimals={0}/> m</td>
                            </tr>
                            <tr>
                              <td>End</td><td style={{textAlign: 'right'}}><Number value={this.state.road.end_km} decimals={0}/> m</td>
                            </tr>

                          </tbody>
                        </Table>
                      </Block>

                    </div>
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span>Landslides <Label>{this.state.road.landslides_count}</Label></span>}>
                <ListLandslides auth={p.auth} name="road_landslides" roadId={this.state.road.id} onClick={this.handleLandslideClick}/>
              </Tabs.Pane>                
              <Tabs.Pane label={<span>Issues <Label>{this.state.road.issues_count}</Label></span>}>
                <ListIssues auth={p.auth} name="road_issues" roadId={this.state.road.id} onClick={this.handleIssueClick}/>
              </Tabs.Pane>                
            </Tabs>
          </React.Fragment>}
        </Content>
        <BottomBar>
          <div></div>
          <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>          
        </BottomBar>
        <HelpPane title="View road help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
          <p>This view presents all information available on a road in the database.</p>
          <Header size="h3">What's here?</Header>
          <p>
            Three types of information are available: road identification (road code and link code, and the 
            station/region the road is in), road class, and road length. The latter includes the start and
            end distance from the current road link in the road it belongs to.
          </p>
          <Header size="h3">Landslides</Header>
          <p>
            In this database, landslides are usually recorded close to or on roads. Landslides with 
            a reference to the road you are viewing are listed on the <i>Landslides</i> tab. You
            can click any landslide to view details.
          </p>
        </HelpPane>        
      </Container>
    );
  }
}

export { ViewRoad };
