import * as React from 'react';
import { Container, Content, BottomBar, HelpPane } from '../../modules';
import { Issue } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListIssues } from './ListIssues';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Header } from '@independent-software/typeui/controls/Header';
import { Link } from '../../controls/Link';

interface IState {
  showHelp: boolean;
}

class IssueList extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      showHelp: false
    }
  }

  handleAdd = () => {
    this.props.history.push('/issues/add');
  }  

  handleClick = (item: Issue) => {
    this.props.history.push(`/issues/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListIssues auth={p.auth} name="issues" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth.hasRight('can_edit_issues') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> Add</Button>}
          <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>
        </BottomBar>
        <HelpPane title="Issues list help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
            <p>The <b>issues list</b> shows road reserve issues recorded in the database.</p>
            <Header size="h3">What's here?</Header>
            <p>
              This list presents road reserve issues by road and by type.
            </p>
            <Header size="h3">Viewing an issue</Header>
            <p>
              You can click a record in the list to view all details for a road reserve issue.
            </p>
            <Header size="h3">Sorting the list</Header>
            <p>
              You can sort the list by road or by type by clicking the list column headers.
            </p>
            <Header size="h3">Filtering the list</Header>
            <p>
              Use the <Icon name="tools"/> icon to filter the list. You can filter by road and by type.
            </p>
            <Header size="h3">Exporting data</Header>
            <p>
              Any data you currently see can be exported to Microsoft Excel, Open Office or CSV formats. Your
              current ordering or list filter settings will be reflected in the exported data. To export,
              use the <Icon name="tools"/> icon and click <b>Export</b>.
            </p>
            {p.auth.hasRight('can_edit_issues') && <React.Fragment>
              <Header size="h3">Adding data</Header>
              <p>To add new issue information, click the <Link to="issues/add">Add</Link> button.</p>
            </React.Fragment>}
        </HelpPane>        
      </Container>
    );
  }
}

export { IssueList };
