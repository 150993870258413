import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

interface IProps {
  className?: string;
  roads: boolean;
  stations: boolean;
  susceptibility: boolean;
  onToggle: (roads: boolean, stations: boolean, susceptibility: boolean) => void;
}

//
// LayerToggle presents checkboxes for reference layers
// to be toggled on or off.
// 
class LayerToggle extends React.Component<IProps, {}> {
  private handleChangeRoads = () => {
    this.props.onToggle(!this.props.roads, this.props.stations, this.props.susceptibility);
  }

  private handleChangeStations = () => {
    this.props.onToggle(this.props.roads, !this.props.stations, this.props.susceptibility);
  }  

  private handleChangeSusceptibility = () => {
    this.props.onToggle(this.props.roads, this.props.stations, !this.props.susceptibility);
  }  


  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Checkbox checked={p.roads} label="Roads" onChange={this.handleChangeRoads}/>
        <Checkbox checked={p.stations} label="Stations" onChange={this.handleChangeStations}/>
        <Checkbox checked={p.susceptibility} label="Susceptibility" onChange={this.handleChangeSusceptibility}/>
      </div>
    )
  }
}

export { LayerToggle };
