import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Segment } from '@independent-software/typeui/controls/Segment';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

class MapControlsBase extends React.Component<IProps, {}> {
  render() {
    return (
      <div className={this.props.className}>
        <Segment raised>
          <div className='content'>
            {this.props.children}
          </div>
        </Segment>
      </div>
    );
  }
}

const MapControls = styled(MapControlsBase)`
  position: absolute;
  left: 20px;
  bottom: 5px;
  .content {
    display: flex;
    flex-direction: horizontal;
  }
`

export { MapControls };


