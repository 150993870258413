import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  className?: string;
  left: string;
  right: string;
  stops: any[];
}

class GradientBase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Separator/>
        <div>{p.left}</div>
        <div className='grad'></div>
        <div>{p.right}</div>
      </div>
    );
  }
}

const Gradient = styled(GradientBase)`
  position: relative;
  display: flex;
  flex-direction: horizontal;
  padding-left: 20px;
  .grad {
    width: 200px;
    height: 20px;
    margin: 2px 5px 0 5px;
    background: linear-gradient(to right, ${p => p.stops.join(',')});
    border-radius: 4px;
  }
`

const Separator = styled('div')`
  position: absolute;
  left: 0;
  top: -10px;
  bottom: -10px;
  width: 1px;
  border-left: solid 1px lightgray;
`

export { Gradient };


/* let gradientCSS = this.state.stops.map(stop => `rgb(${stop[1]},${stop[2]},${stop[3]}) ${stop[0]*100}%`).join();
return (
  <div className={p.className}>
    <Layer>{this.state.name} ({this.state.unit})</Layer>
    <Min><Number value={this.state.min} decimals={2}/></Min>
    <Bar style={{background: `linear-gradient(to right, ${gradientCSS})`}}/>
    <Max><Number value={this.state.max} decimals={2}/></Max>
*/