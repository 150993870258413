import * as React from 'react';
import { Container, Content, BottomBar, Section } from '../../modules';
import { StationForm } from './StationForm';
import { StationFactory, Station } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';

type TStep = 'ready' | 'saving' | 'saveError';

interface IState {
  station: Station;
  isValid: boolean;
  step: TStep;
  error: any;
  dirty?: boolean;
}

class AddStation extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  private station: Station;

  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.station = StationFactory.create();
    this.state = {
      step: 'ready',
      isValid: false,
      error: null,
      station: this.station
    };
  }

  handleChange = (station: Station, forceupdate: boolean) => {
    this.station = Object.assign(this.station, station);
    if(forceupdate) { 
      this.setState({ station: this.station });  
    }
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  handleSubmit = () => {
    this.setState({ station: this.station, dirty: true });
    if(!this.state.isValid) return;    

    this.setState({error: null, step: 'saving'});
    this.station.$save(this.props.auth)
      .then(res => {
        ToastService.toast("Station created");
        let id = res.data;
        this.props.history.push(`/stations/${id}`);
      })
      .catch(error => {
        this.setState({
          step: 'saveError',
          error: error
        })
      });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {this.state.step == 'saving' && <Loader></Loader>}
        <React.Fragment>
          <Content>
            <Section padded>
              <StationForm auth={p.auth} dirty={this.state.dirty} data={this.state.station} onChange={this.handleChange} onValidate={this.handleValidate}/>
            </Section>
          </Content>
          <BottomBar>
            <Button primary onClick={this.handleSubmit}><Icon name="save"/> Save</Button>
          </BottomBar>
        </React.Fragment>
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave} onRetry={this.handleSubmit}/>
      </Container>
    );
  }
}

export { AddStation };
