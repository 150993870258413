interface ICancelablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}

function makeCancelable<U>(promise: Promise<U>): ICancelablePromise<U> {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<U>((resolve, reject) => {
    promise.then(
      val   => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
      error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

export { ICancelablePromise, makeCancelable }