import * as React from 'react';
import { Station, StationFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export, NA } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';

interface IProps extends IListProps {
  /**
   * Event is fired when an item is clicked.
   */
  onClick?: (station: Station) => void;
}

interface IState extends IListState<Station> {
}

class ListStations extends List<Station, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, StationFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeFilterUsersEmpty = (value: boolean) => {
    this.setFilter('users', 'eq', value ? 0 : null);
  }

  handleChangeFilterLandslidesNotEmpty = (value: boolean) => {
    this.setFilter('landslides', 'neq', value ? 0 : null);
  }  

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search records">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="">
          <Checkbox label="Only stations without users" type="toggle" checked={this.getFilter('users', 'eq') != null} onChange={this.handleChangeFilterUsersEmpty}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="">
          <Checkbox label="Only stations with landslides" type="toggle" checked={this.getFilter('landslides', 'neq') != null} onChange={this.handleChangeFilterLandslidesNotEmpty}/>
        </Form.Uncontrolled>        
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon={{name:'tools', title: 'Filter', inverted: true, cornered: true, bordered: true}} width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={4} label="Name" order="name" dir="asc">{(item:Station) => <span>{item.name}</span>}</DataTable.Column>
          <DataTable.Column align="right" weight={1} label="Landslides" order="landslides_count" dir="desc">{(item:Station) => item.landslides_count == 0 ? <NA/> : item.landslides_count}</DataTable.Column>
          <DataTable.Column align="right" weight={1} label="Issues" order="issues_count" dir="desc">{(item:Station) => item.issues_count == 0 ? <NA/> : item.issues_count}</DataTable.Column>
          <DataTable.Column align="right" weight={1} label="Users" order="users_count" dir="desc">{(item:Station) => item.users_count == 0 ? <NA/> : item.users_count}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListStations };
