import * as React from 'react';
import { Section, Viewer } from '../../modules';
import { Station, StationFactory, User, Landslide, Issue } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { ListUsers } from '../User';
import { ValueStore } from '../../services';
import { ListLandslides } from '../Landslide/ListLandslides';

import { Header } from '@independent-software/typeui/controls/Header';
import { Label } from '@independent-software/typeui/controls/Label';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { ListIssues } from '../Issue/ListIssues';

class ViewStation extends React.Component<IAuthProps & RouteComponentProps<{id: string}>, {}> {
  
  private canEdit = (station: Station): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_stations');
  }

  private handleEdit = () => {
    this.props.history.push(`/stations/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Station deleted");
    this.props.history.goBack();    
  }

  private handleUserClick = (item:User) => {
    this.props.history.push(`/users/${item.id}`);
  }  

  private handleLandslideClick = (item:Landslide) => {
    this.props.history.push(`/landslides/${item.id}`);
  }  

  private handleIssueClick = (item:Issue) => {
    this.props.history.push(`/issues/${item.id}`);
  }  

  private handleTabChange = (index: number) => {
    ValueStore.set("view_station", index);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Station> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={StationFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(item: Station) => 
          <React.Fragment>
            <Section dark padded>
              <Header size="h3">
                {item.name}
              </Header>
            </Section>
            <Tabs underlined active={ValueStore.get('view_station', 0)} onTabChange={this.handleTabChange}>
              <Tabs.Pane label={<span>Users <Label>{item.users_count}</Label></span>}>
                <ListUsers auth={p.auth} name="station_users" stationId={item.id} onClick={this.handleUserClick}/>
              </Tabs.Pane>                
              <Tabs.Pane label={<span>Landslides <Label>{item.landslides_count}</Label></span>}>
                <ListLandslides auth={p.auth} name="station_landslides" stationId={item.id} onClick={this.handleLandslideClick}/>
              </Tabs.Pane>                
              <Tabs.Pane label={<span>Issues <Label>{item.issues_count}</Label></span>}>
                <ListIssues auth={p.auth} name="station_issues" stationId={item.id} onClick={this.handleIssueClick}/>
              </Tabs.Pane>                
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewStation };
