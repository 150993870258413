import * as React from 'react';
import { User, ProfileFactory, Profile, Station, StationFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Block } from '../../modules';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';


interface IProps {
  /** Initial form data. */
  data: User;
  /** Called whenever form changes. */
  onChange: (data: User, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
  // Show sendmail option?
  sendmail?: boolean;
}

interface IState {
  /** Current form data. */
  data: User;
  /** Data for profiles dropdown */
  profiles: Profile[];  
  /** Data for stations dropdown */
  stations: Station[];
}

class UserForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      profiles: [],
      stations: []
    };    
  }

  componentDidMount() {
    // Retrieve a list of profiles:
    ProfileFactory.getSome(this.props.auth, 0, 5, new Query('name', 'asc'))
      .then((res) => this.setState({ profiles: res.items }));    

    // Retrieve a list of stations:
    StationFactory.getSome(this.props.auth, 0, 5, new Query('name', 'asc'))
      .then((res) => this.setState({ stations: res.items }));    
  }

  handleProfileSearch = (q:string) => {
    // Retrieve a list of profiles:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    ProfileFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ profiles: res.items }));
  }  

  handleStationSearch = (q:string) => {
    // Retrieve a list of stations:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    StationFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ stations: res.items }));
  }    

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Flex.Columns count={2}>
          <Block title="Identification">
            <Form.Field 
              required={{message: "Name is required"}}
              minLength={{length: 3, message: "Name must be at least 3 characters"}}
              maxLength={{length: 30, message: "Name may not be longer than 30 characters"}}
              width={1} label="Username"
              name="name" 
              value={this.state.data.name}
              control={(<Input type="text" placeholder="Name" fluid/>)}
              hint={(<React.Fragment>Please enter a username, e.g. <code>john.smith</code></React.Fragment>)}/>
            <Form.Field 
              required={{message: "Email is required"}}
              email={{message: "Email must be a valid email address"}}
              width={1} label="Email"
              name="email" 
              value={this.state.data.email}
              control={(<Input type="text" placeholder="Email" fluid/>)}
              hint={(<React.Fragment>Please enter the user's email address, e.g. <code>john.smith@gmail.com</code></React.Fragment>)}/>
          </Block>
          <Block title="Access">
            <Form.Field
              required={{message: "An access profile is required"}}
              width={1} label="Profile"
              name="profile" 
              value={this.state.data.profile}
              control={(<Dropdown onSearch={this.handleProfileSearch} fluid data={this.state.profiles} placeholder="Profile" label={(item:Profile) => item.name}>
                <Dropdown.Column weight={3}>{(item:Profile) => item.name}</Dropdown.Column>
                <Dropdown.Column align='right'>{(item:Profile) => item.users_count}</Dropdown.Column>
              </Dropdown>)}
              hint="Select access profile"/>
            <Form.Field
              width={1} label="Station"
              name="station" 
              value={this.state.data.station}
              control={(<Dropdown clearable onSearch={this.handleStationSearch} fluid data={this.state.stations} placeholder="Station" label={(item:Station) => item.name}>
                <Dropdown.Column weight={3}>{(item:Station) => item.name}</Dropdown.Column>
                <Dropdown.Column align='right'>{(item:Station) => item.users_count}</Dropdown.Column>
              </Dropdown>)}
              hint="Select station (optional)"/>
            </Block>
        </Flex.Columns>

        {p.sendmail && <Flex.Columns count={2}>
          <Block title="Options" dark>
            <Form.Field 
              name="sendmail" value={this.state.data.sendmail}
              control={<Checkbox label="Send mail to user" checked={this.state.data.sendmail}/>}/>
          </Block>             
        </Flex.Columns>}
      </Form>
    )
  }
}

export { UserForm };
