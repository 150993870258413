/**
 * Rights available in application.
 * 
 * WARNING WARNING: When you add a right here, also add it to resource/Profile.ts,
 * as well as to API:Rights.php !!!
 */
type TRight = 
    'can_view_users' 
  | 'can_edit_users'
  | 'can_view_profiles'
  | 'can_edit_profiles'
  | 'can_view_logs'
  | 'can_view_stations'
  | 'can_edit_stations'
  | 'can_edit_landslides'
  | 'can_edit_issues';
  // WARNING WARNING WARNING see above.

interface IAccessGroup {
  title: string;
  rights: IRight[];
}

interface IRight {
  title: string;
  name: TRight;
}

let accessGroups: IAccessGroup[] = [
  {
    title: 'User management',
    rights: [
      { title: 'View users', name: 'can_view_users' },
      { title: 'Edit users', name: 'can_edit_users' }
    ]
  }, {
    title: 'Profile management',
    rights: [
      { title: 'View profiles', name: 'can_view_profiles' },
      { title: 'Edit profiles', name: 'can_edit_profiles' }
    ]
  }, {
    title: 'Log management',
    rights: [
      { title: 'View logs', name: 'can_view_logs'}
    ]
  }, {
    title: 'Station management',
    rights: [
      { title: 'View stations', name: 'can_view_stations'},
      { title: 'Edit stations', name: 'can_edit_stations'}
    ]
  }, {
    title: 'Landslide management',
    rights: [
      { title: 'Edit landslides', name: 'can_edit_landslides'}
    ]
  }, {
    title: 'Road reserve issue management',
    rights: [
      { title: 'Edit issues', name: 'can_edit_issues' }
    ]
  }
];

export { TRight, IAccessGroup, IRight, accessGroups };