import * as React from 'react';
import { MapLayer } from './MapLayer';
import { IStation } from '../types/IStation';

class StationsLayer extends MapLayer<IStation> {
  
  protected getResource = (): string => {
    return "regions";
  }

  protected getStyle = (item: IStation, zoom: number): google.maps.Data.StyleOptions => {
    return {
      strokeColor: this.props.selected == item ? 'red' : 'olive',
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillColor: '#666',
      fillOpacity: 0.1,
      clickable: true
    };
  }
}

export { StationsLayer };