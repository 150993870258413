import { Model, ResourceFactory } from '../services/';
import { IMBR } from './IMBR';

class Region extends Model implements IMBR {
  public name: string = '';
  public minLat: number;
  public minLng: number;
  public maxLat: number;
  public maxLng: number;
}

let RegionFactory = ResourceFactory.create<Region>(Region, 'region');

export { Region, RegionFactory };