import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { RoadFactory, Road, IMBR } from '../resource';
import { Query, IAuthProps } from '../services';
import { Form } from '@independent-software/typeui/controls/Form';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';

interface IProps {
  onSelect: (mbr: IMBR) => void;
}

interface IState {
  /** Data for roads dropdown */
  roads: Road[];
  road: Road;
}

class RoadSearch extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      roads: [],
      road: null
    };    
  }

  componentDidMount() {
    // Retrieve a list of roads:
    RoadFactory.getSome(this.props.auth, 0, 5, new Query('road', 'asc'))
      .then((res) => this.setState({ roads: res.items }));    
  }

  handleRoadSearch = (q:string) => {
    // Retrieve a list of roads:
    let query = new Query('road', 'asc');
    query.setFilter('q', 'like', q);
    RoadFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ roads: res.items }));
  }

  handleSelectRoad = (road: Road) => {
    this.setState({
      road: road
    });
    if(road != null) this.props.onSelect(road);
  }

  render() {
    return (
      <Form.Uncontrolled hint="Find road">
        <Dropdown onSearch={this.handleRoadSearch} name="road" fluid value={this.state.road} data={this.state.roads} placeholder="Road" label={(item:Road) => <span>{item.road} &mdash; {item.linkid} &mdash; {item.link_name}</span> } onChange={this.handleSelectRoad}>
          <Dropdown.Column weight={1}>{(item:Road) => item.road}</Dropdown.Column>
          <Dropdown.Column weight={2}>{(item:Road) => item.linkid}</Dropdown.Column>
          <Dropdown.Column weight={4}>{(item:Road) => item.link_name}</Dropdown.Column>
        </Dropdown>
      </Form.Uncontrolled>
    );
  }
}

export { RoadSearch };
