import * as React from 'react';
import { Container, Content, BottomBar, Section, HelpPane } from '../../modules';
import { Landslide } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListLandslides } from './ListLandslides';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Header } from '@independent-software/typeui/controls/Header';
import { Link } from '../../controls/Link';

interface IState {
  showHelp: boolean;
}

class LandslideList extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      showHelp: false
    }
  }

  handleAdd = () => {
    this.props.history.push('/landslides/add');
  }  

  handleClick = (item: Landslide) => {
    this.props.history.push(`/landslides/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListLandslides auth={p.auth} name="landslides" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth.hasRight('can_edit_landslides') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> Add</Button>}
          <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>
        </BottomBar>
        <HelpPane title="Landslide list help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
            <p>The <b>landslide list</b> shows landslides recorded in the database.</p>
            <Header size="h3">What's here?</Header>
            <p>
              This list presents landslides by date, station, road code/link and type. It also shows
              which landslides were imported from external sources.
            </p>
            <Header size="h3">Viewing a landslide</Header>
            <p>
              You can click a record in the list to view all details for a landslide.
            </p>
            <Header size="h3">Sorting the list</Header>
            <p>
              You can sort the list by date by clicking the list column headers.
            </p>
            <Header size="h3">Filtering the list</Header>
            <p>
              Use the <Icon name="tools"/> icon to filter the list. You can filter by road, by station,
              and by date range. You can also filter out landslides imported from external sources, or
              landslides with no date.
            </p>
            <Header size="h3">Exporting data</Header>
            <p>
              Any data you currently see can be exported to Microsoft Excel, Open Office or CSV formats. Your
              current ordering or list filter settings will be reflected in the exported data. To export,
              use the <Icon name="tools"/> icon and click <b>Export</b>.
            </p>
            {p.auth.hasRight('can_edit_landslides') && <React.Fragment>
              <Header size="h3">Adding data</Header>
              <p>To add new landslide information, click the <Link to="landslides/add">Add</Link> button.</p>
            </React.Fragment>}
        </HelpPane>        
      </Container>
    );
  }
}

export { LandslideList };
