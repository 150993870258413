import * as React from 'react';
import styled, { Theme } from '@independent-software/typeui/styles/Theme'

import { Landslide, Road, RoadFactory } from '../../resource/';
import { IAuthProps, Query } from '../../services/';
import { Block, Section } from '../../modules';
import { SingleMarkerMap } from '../../map';

import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Label } from '@independent-software/typeui/controls/Label';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Message } from '@independent-software/typeui/controls/Message';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { SYMBOL } from '../../map/Symbol';

interface IProps {
  /** Initial form data. */
  data: Landslide;
  /** Called whenever form changes. */
  onChange: (data: Landslide, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Landslide;
  /** Data for roads dropdown */
  roads: Road[];    
}

class LandslideForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      roads: []
    };    
  }

  componentDidMount() {
    // Retrieve a list of roads:
    RoadFactory.getSome(this.props.auth, 0, 10, new Query('linkid', 'asc'))
      .then((res) => this.setState({ roads: res.items }));    
  }

  handleRoadSearch = (q:string) => {
    // Retrieve a list of roads:
    let query = new Query('linkid', 'asc');
    query.setFilter('q', 'like', q);
    RoadFactory.getSome(this.props.auth, 0, 10, query)
      .then((res) => this.setState({ roads: res.items }));
  }    

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined>
          <Tabs.Pane label="General">
            <Stretch>
              <StretchInner>
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Date">
                        <Form.Field 
                          label="Date"
                          name="date" 
                          value={this.state.data.date}
                          control={(<Input type="date" placeholder="Date of landslide" fluid dateformat="E, d MMM yyyy"/>)}
                          hint={(<React.Fragment>Date the landslide occurred</React.Fragment>)}/>
                      </Block>

                      <Block title="Rain">
                        <Form.Field 
                          name="rain" value={this.state.data.rain}
                          control={<Checkbox label="Did the landslide occur during or after heavy rain?" checked={this.state.data.rain}/>}/>
                      </Block>            

                      <Block title="Cause">
                        <Form.Field 
                          name="cause" value={this.state.data.cause}
                          control={(<MarkdownTextarea rows={10} fluid/>)}
                          hint={(<React.Fragment>Are you aware of any other causes of the landslide?</React.Fragment>)}/>
                      </Block>
                    </div>
                    <div>
                      <Block title="Surface">
                        <Form.Field
                          required={{message: "Surface is required"}}
                          name="surface"
                          value={this.state.data.surface}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="surface" value="asphalt" label="Asphalt" checked={this.state.data.surface=='asphalt'} onChange={() => this.props.onChange({...this.state.data, surface: 'asphalt'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="surface" value="gravel" label="Gravel" checked={this.state.data.surface=='gravel'} onChange={() => this.props.onChange({...this.state.data, surface: 'gravel'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="surface" value="other" label="Other" checked={this.state.data.surface=='other'} onChange={() => this.props.onChange({...this.state.data, surface: 'other'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>Select the road surface. If the surface is not in the list, select <b>Other</b> and desribe the surface.</React.Fragment>}/>
                          {this.state.data.surface == 'other' && <Padding><Form.Field 
                            required={{message: "Surface description is required"}}
                            label="Surface description"
                            name="surface_other" 
                            value={this.state.data.surface_other}
                            control={(<Input type="text" placeholder="Surface description" fluid/>)}
                            hint={(<React.Fragment>Description of the road surface</React.Fragment>)}/></Padding>}
                      </Block>

                      <Block title="Road length affected">
                        <Form.Field 
                          isFloat={{message: "Road length must be a number"}}
                          min={{value: 0, message: "Road length must be positive"}}
                          label="Road length"
                          name="length_total" 
                          value={this.state.data.length_total}
                          control={(<Input type="text" placeholder="Road length" fluid><Label attached="right" basic pointing>m</Label></Input>)}
                          hint={(<React.Fragment>Affected road length in meters</React.Fragment>)}/>              
                      </Block>

                      <Block title="Type">
                        <Form.Field
                          required={{message: "Type is required"}}
                          name="type"
                          value={this.state.data.type}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="type" value="new" label="A new landslide" checked={this.state.data.type=='new'} onChange={() => this.props.onChange({...this.state.data, type: 'new'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="type" value="reactivation" label="Reactivation of an existing landslide" checked={this.state.data.type=='reactivation'} onChange={() => this.props.onChange({...this.state.data, type: 'reactivation'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="type" value="extension" label="Extension of an existing landslide" checked={this.state.data.type=='extension'} onChange={() => this.props.onChange({...this.state.data, type: 'extension'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>Select the type of landslide</React.Fragment>}/>
                      </Block>            
                    </div>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>

          <Tabs.Pane label="Location">
            <Stretch>
              <StretchInner>
                <SingleMarkerMap 
                  auth={p.auth}
                  lat={parseFloat(this.state.data.latitude)} 
                  lng={parseFloat(this.state.data.longitude)}
                  onClick={(lat, lng) => this.props.onChange({...this.state.data, latitude: lat.toString(), longitude: lng.toString() }, true)}
                  symbol={SYMBOL}
                />
                <Section padded>
                  <Message type="info">
                    Click the map to set a landslide location.
                  </Message>

                  <Block title="Road">
                    <Form.Field
                      width={1} label="Road"
                      name="road" 
                      value={this.state.data.road}
                      control={(<Dropdown onSearch={this.handleRoadSearch} fluid clearable data={this.state.roads} placeholder="Road" label={(item:Road) => <span>{item.road} &mdash; {item.linkid} &mdash; {item.link_name}</span>}>
                        <Dropdown.Column weight={1}>{(item:Road) => item.road}</Dropdown.Column>
                        <Dropdown.Column weight={2}>{(item:Road) => item.linkid}</Dropdown.Column>
                        <Dropdown.Column weight={4}>{(item:Road) => item.link_name}</Dropdown.Column>
                      </Dropdown>)}
                      hint="Select road where the landslide occurred"/>                          
                    <Flex.Columns count={2}>
                      <Form.Field 
                        label="Road from"
                        name="location_from" 
                        value={this.state.data.location_from}
                        control={(<Input type="text" placeholder="Road from" fluid/>)}
                        hint={(<React.Fragment>Town where the road section starts, e.g. <code>Bujuko</code></React.Fragment>)}/>
                      <Form.Field 
                        label="Road to"
                        name="location_to" 
                        value={this.state.data.location_to}
                        control={(<Input type="text" placeholder="Road to" fluid/>)}
                        hint={(<React.Fragment>Town where the road section ends, e.g. <code>Bujuko</code></React.Fragment>)}/>
                    </Flex.Columns>
                    <Flex.Columns count={2}>
                      <Form.Field 
                        isFloat={{message: "Distance must be a number"}}
                        min={{value: 0, message: "Distance must be positive"}}
                        label="Distance"
                        name="location_km" 
                        value={this.state.data.location_km}
                        control={(<Input type="text" placeholder="Distance" fluid><Label attached="right" basic pointing>km</Label></Input>)}
                        hint={(<React.Fragment>Distance from the start town in kilometers</React.Fragment>)}/>
                    </Flex.Columns>
                  </Block>

                  <Block title="GPS">
                    <Flex.Columns count={2}>
                      <Form.Field 
                        forceupdate
                        required={{message: "Latitude is required"}}
                        isFloat={{message: "Latitude must be a number"}}
                        label="Latitude"
                        name="latitude" 
                        value={this.state.data.latitude}
                        control={(<Input type="text" placeholder="Latitude" fluid/>)}
                        hint={(<React.Fragment>Latitude where landslide occurred, e.g. <code>1.495731</code>. Use <code>0</code> if unknown</React.Fragment>)}/>
                      <Form.Field 
                        forceupdate
                        required={{message: "Longitude is required"}}
                        isFloat={{message: "Longitude must be a number"}}
                        label="Longitude"
                        name="longitude" 
                        value={this.state.data.longitude}
                        control={(<Input type="text" placeholder="Longitude" fluid/>)}
                        hint={(<React.Fragment>Lontigude where landslide occurred, e.g. <code>32.630194</code>. Use <code>0</code> if unknown</React.Fragment>)}/>
                    </Flex.Columns>
                  </Block>                              

                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>

          <Tabs.Pane label="Origin">
            <Stretch>
              <StretchInner>
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Origin">
                        <Form.Field
                          required={{message: "Origin is required"}}
                          name="origin" label="Did the landslide originate:"
                          value={this.state.data.origin}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="origin" value="inside" label="Inside the Road Reserve" checked={this.state.data.origin=='inside'} onChange={() => this.props.onChange({...this.state.data, origin: 'inside'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="origin" value="above" label="From the natural slopes above the Road Reserve" checked={this.state.data.origin=='above'} onChange={() => this.props.onChange({...this.state.data, origin: 'above'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="origin" value="below" label="From the natural slopes below the Road Reserve" checked={this.state.data.origin=='below'} onChange={() => this.props.onChange({...this.state.data, origin: 'below'}, true)}/></Padding>
                          </React.Fragment>}/>
                        {this.state.data.origin=='inside' && <React.Fragment>
                          <Form.Field
                            required={{message: "Failure type is required"}}
                            name="origin_inside_type" label="Was it a failure of:"
                            value={this.state.data.origin_inside_type}
                            control={<React.Fragment>
                              <Padding><Checkbox radio type="circle" name="origin_inside_type" value="cut" label="Cut slope" checked={this.state.data.origin_inside_type=='cut'} onChange={() => this.props.onChange({...this.state.data, origin_inside_type: 'cut'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="origin_inside_type" value="fill" label="Fill slope" checked={this.state.data.origin_inside_type=='fill'} onChange={() => this.props.onChange({...this.state.data, origin_inside_type: 'fill'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="origin_inside_type" value="wall" label="Retaining wall" checked={this.state.data.origin_inside_type=='wall'} onChange={() => this.props.onChange({...this.state.data, origin_inside_type: 'wall'}, true)}/></Padding>                      
                            </React.Fragment>}/>
                          {this.state.data.origin_inside_type=='wall' && <React.Fragment>
                            <Form.Field
                              required={{message: "Retaining wall is required"}}
                              name="origin_wall" label="Was the retaining wall:"
                              value={this.state.data.origin_wall}
                              control={<React.Fragment>
                                <Padding><Checkbox radio type="circle" name="origin_wall" value="above" label="Above" checked={this.state.data.origin_wall=='above'} onChange={() => this.props.onChange({...this.state.data, origin_wall: 'above'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="origin_wall" value="below" label="Below" checked={this.state.data.origin_wall=='below'} onChange={() => this.props.onChange({...this.state.data, origin_wall: 'below'}, true)}/></Padding>
                              </React.Fragment>}/>
                          </React.Fragment>}
                        </React.Fragment>}
                      </Block>
                    </div>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>

          <Tabs.Pane label="Impact">
            <Stretch>
              <StretchInner>
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Blockage">
                        <Form.Field
                          required={{message: "Blockage is required"}}
                          name="blockage"
                          value={this.state.data.blockage}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="blockage" value="none" label="No blockage" checked={this.state.data.blockage == 'none'} onChange={() => this.props.onChange({...this.state.data, blockage: 'none'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="blockage" value="drain" label="Blockage to side drain" checked={this.state.data.blockage == 'drain'} onChange={() => this.props.onChange({...this.state.data, blockage: 'drain'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="blockage" value="carriageway" label="Blockage to side drain and inside carriageway" checked={this.state.data.blockage == 'carriageway'} onChange={() => this.props.onChange({...this.state.data, blockage: 'carriageway'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="blockage" value="road" label="Blockage to entire road" checked={this.state.data.blockage == 'road'} onChange={() => this.props.onChange({...this.state.data, blockage: 'road'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>Was there blockage of any part of the road?</React.Fragment>}/>
                      </Block>
                      <Block title="Cracking">
                        <Form.Field
                          required={{message: "Cracking is required"}}
                          name="cracking"
                          value={this.state.data.cracking}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="cracking" value="none" label="No cracking" checked={this.state.data.cracking == 'none'} onChange={() => this.props.onChange({...this.state.data, cracking: 'none'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="cracking" value="shoulder" label="Cracking to outside shoulder" checked={this.state.data.cracking == 'shoulder'} onChange={() => this.props.onChange({...this.state.data, cracking: 'shoulder'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="cracking" value="carriageway" label="Cracking to shoulder and outside carriageway" checked={this.state.data.cracking == 'carriageway'} onChange={() => this.props.onChange({...this.state.data, cracking: 'carriageway'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="cracking" value="road" label="Cracking to entire road" checked={this.state.data.cracking == 'road'} onChange={() => this.props.onChange({...this.state.data, cracking: 'road'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>Was there cracking of any part of the road?</React.Fragment>}/>
                      </Block>  
                      <Block title="Displacement">
                        <Form.Field
                          required={{message: "Displacement is required"}}
                          name="displacement"
                          value={this.state.data.displacement}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="displacement" value="none" label="No displacement" checked={this.state.data.displacement == 'none'} onChange={() => this.props.onChange({...this.state.data, displacement: 'none'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="displacement" value="carriageway" label="Displacement of outside carriageway" checked={this.state.data.displacement == 'carriageway'} onChange={() => this.props.onChange({...this.state.data, displacement: 'carriageway'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="displacement" value="road" label="Displacement of entire road" checked={this.state.data.displacement == 'road'} onChange={() => this.props.onChange({...this.state.data, displacement: 'road'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>Was there displacement of any part of the road?</React.Fragment>}/>
                      </Block>                                      
                    </div>
                    <div>
                      <Block title="Road closure">
                        <Form.Field 
                          name="road_closed" forceupdate value={this.state.data.road_closed}
                          control={<Checkbox label="Did the road have to be closed?" checked={this.state.data.road_closed}/>}/>
                        {this.state.data.road_closed == true && <React.Fragment>
                          <Form.Field 
                            required={{message: "Road closure time is required"}}
                            isFloat={{message: "Road closure time must be a number"}}
                            min={{value: 0, message: "Road closure time must be positive"}}
                            label="For how long?"
                            name="road_closed_duration" 
                            value={this.state.data.road_closed_duration}
                            control={(
                              <Input type="text" placeholder="Duration" fluid>
                                <Label attached="right" color={this.state.data.road_closed_duration_unit=='hour' ? Theme.primaryColor : ''} onClick={() => this.props.onChange({...this.state.data, road_closed_duration_unit: 'hour'}, true)}>hours</Label>
                                <Label attached="right" color={this.state.data.road_closed_duration_unit=='day' ? Theme.primaryColor : ''} onClick={() => this.props.onChange({...this.state.data, road_closed_duration_unit: 'day'}, true)}>days</Label>
                              </Input>)}
                            hint={(<React.Fragment>Please indicate how long the road was closed.</React.Fragment>)}/>
                        </React.Fragment>}
                      </Block>                                      
                      <Block title="Volume">
                        <Form.Field 
                          isFloat={{message: "Volume must be a number"}}
                          min={{value: 0, message: "Volume must be a positive number"}}
                          label="Volume"
                          name="volume" 
                          value={this.state.data.volume}
                          control={(<Input type="text" placeholder="Volume" fluid><Label attached="right" pointing basic>m<sup>3</sup></Label></Input>)}
                          hint={(<React.Fragment>Provide an estimate of the volume displaced</React.Fragment>)}/>
                      </Block>

                    </div>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>

          <Tabs.Pane label="Land use">
            <Stretch>
              <StretchInner>
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Land use">
                        <Question>What was the land use in the source area of the landslide? Please select all options that apply.</Question>
                        <Form.Field forceupdate name="land_use_forest" value={this.state.data.land_use_forest} 
                          control={<Checkbox label="Forest" checked={this.state.data.land_use_forest}/>}/>
                        <Form.Field forceupdate name="land_use_dry_cultivation" value={this.state.data.land_use_dry_cultivation} 
                          control={<Checkbox label="Dry cultivation" checked={this.state.data.land_use_dry_cultivation}/>}/>
                        <Form.Field forceupdate name="land_use_irrigated_cultivation" value={this.state.data.land_use_irrigated_cultivation} 
                          control={<Checkbox label="Irrigated cultivation" checked={this.state.data.land_use_irrigated_cultivation}/>}/>
                        <Form.Field forceupdate name="land_use_other" value={this.state.data.land_use_other} 
                          control={<Checkbox label="Other" checked={this.state.data.land_use_other}/>}/>
                        {this.state.data.land_use_other == true && <Form.Field 
                          required={{message: "Land use description is required"}}
                          name="land_use_other_description" 
                          value={this.state.data.land_use_other_description}
                          control={(<MarkdownTextarea rows={10} fluid/>)}
                          hint={(<React.Fragment>Describe the land use</React.Fragment>)}/>}
                      </Block>            

                      <Block title="Drainage condition">
                        <Form.Field
                          required={{message: "Drainage condition is required"}}
                          name="drainage_condition"
                          value={this.state.data.drainage_condition}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="drainage_condition" value="wet" label="Wet with springs and seepage" checked={this.state.data.drainage_condition == 'wet'} onChange={() => this.props.onChange({...this.state.data, drainage_condition: 'wet'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="drainage_condition" value="dry" label="Dry" checked={this.state.data.drainage_condition == 'dry'} onChange={() => this.props.onChange({...this.state.data, drainage_condition: 'dry'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="drainage_condition" value="intermittent" label="Intermittent dry and wet" checked={this.state.data.drainage_condition == 'intermittent'} onChange={() => this.props.onChange({...this.state.data, drainage_condition: 'intermittent'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>What is the drainage condition of the slope?</React.Fragment>}/>
                      </Block>
                    </div>
                    <div>

                      <Block title="Land use change">
                        <Form.Field
                          required={{message: "Land use change is required"}}
                          name="land_use_change"
                          value={this.state.data.land_use_change}
                          control={<React.Fragment>
                            <Padding><Checkbox radio type="circle" name="land_use_change" value="yes" label="Yes" checked={this.state.data.land_use_change == 'yes'} onChange={() => this.props.onChange({...this.state.data, land_use_change: 'yes'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="land_use_change" value="no" label="No" checked={this.state.data.land_use_change == 'no'} onChange={() => this.props.onChange({...this.state.data, land_use_change: 'no'}, true)}/></Padding>
                            <Padding><Checkbox radio type="circle" name="land_use_change" value="unknown" label="Unknown" checked={this.state.data.land_use_change == 'unknown'} onChange={() => this.props.onChange({...this.state.data, land_use_change: 'unknown'}, true)}/></Padding>
                          </React.Fragment>}
                          hint={<React.Fragment>Has the land use changed in recent months?</React.Fragment>}/>
                          {this.state.data.land_use_change == 'yes' && <Padding><Form.Field 
                            required={{message: "Land use change description is required"}}
                            label="Land use description"
                            name="land_use_change_description" 
                            value={this.state.data.land_use_change_description}
                            control={(<MarkdownTextarea rows={10} fluid/>)}
                            hint={(<React.Fragment>Describe the change in land use</React.Fragment>)}/></Padding>}
                      </Block>

                    </div>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>

          <Tabs.Pane label="Reinstatement">
            <Stretch>
              <StretchInner>
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Reinstatement funding">
                        <Form.Field 
                          isFloat={{message: "Reinstatement cost must be a number"}}
                          min={{value: 0, message: "Reinstatement cost must be a positive number"}}
                          label="Reinstatement cost"
                          name="reinstatement_cost" 
                          value={this.state.data.reinstatement_cost}
                          control={(<Input type="text" placeholder="Cost" fluid><Label attached="right" pointing basic>UGX</Label></Input>)}
                          hint={(<React.Fragment>Cost of the reinstatement, in Ugandan Shillings</React.Fragment>)}/>                    
                        <Question>How will the reinstatement works be funded / how were the reinstatement works funded? Please select all options that apply.</Question>
                        <Form.Field forceupdate name="funding_routine_maintenance" value={this.state.data.funding_routine_maintenance} 
                          control={<Checkbox label="Routine maintenance" checked={this.state.data.funding_routine_maintenance}/>}/>
                        <Form.Field forceupdate name="funding_emergency_works" value={this.state.data.funding_emergency_works} 
                          control={<Checkbox label="Emergency Works" checked={this.state.data.funding_emergency_works}/>}/>
                        <Form.Field forceupdate name="funding_disaster_fund" value={this.state.data.funding_disaster_fund} 
                          control={<Checkbox label="National Disaster Fund" checked={this.state.data.funding_disaster_fund}/>}/>
                        <Form.Field forceupdate name="funding_other" value={this.state.data.funding_other} 
                          control={<Checkbox label="Other funding" checked={this.state.data.funding_other}/>}/>
                        {this.state.data.funding_other && <Form.Field 
                          required={{message: "Funding description is required"}}
                          name="funding_other_description" 
                          value={this.state.data.funding_other_description}
                          control={(<MarkdownTextarea rows={10} fluid/>)}
                          hint={(<React.Fragment>Describe the funding</React.Fragment>)}/>}
                      </Block> 
                    </div>
                    <div>
                      <Block title="Reinstatement description">
                        <Form.Field 
                          name="reinstatement_description" 
                          value={this.state.data.reinstatement_description}
                          control={(<MarkdownTextarea rows={10} fluid/>)}
                          hint={(<React.Fragment>How was the road reinstated/how will it be reinstated?</React.Fragment>)}/>
                      </Block>
                    </div>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>
          </Tabs.Pane>           

          <Tabs.Pane label="Other damage">
            <Stretch>
              <StretchInner>
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Block title="Retaining wall">
                        <Form.Field 
                          name="damage_walls" forceupdate value={this.state.data.damage_walls}
                          control={<Checkbox label="Did the landslide cause any damage to retaining walls?" checked={this.state.data.damage_walls}/>}/>
                        {this.state.data.damage_walls == true && <React.Fragment>
                          <Form.Field 
                            required={{message: "Wall length is required"}}
                            isFloat={{message: "Wall length must be a number"}}
                            min={{value: 0, message: "Wall length must be a positive number"}}
                            label="Wall length"
                            name="damage_walls_length" 
                            value={this.state.data.damage_walls_length}
                            control={(<Input type="text" placeholder="Length" fluid><Label attached="right" pointing basic>m</Label></Input>)}
                            hint={(<React.Fragment>Length of damaged wall, in meters e.g. <code>12</code></React.Fragment>)}/>
                          <Form.Field 
                            required={{message: "Wall height is required"}}
                            isFloat={{message: "Wall height must be a number"}}
                            min={{value: 0, message: "Wall height must be a positive number"}}
                            label="Wall height"
                            name="damage_walls_height" 
                            value={this.state.data.damage_walls_height}
                            control={(<Input type="text" placeholder="Height" fluid><Label attached="right" pointing basic>m</Label></Input>)}
                            hint={(<React.Fragment>Height of damaged wall, in meters e.g. <code>3</code></React.Fragment>)}/>                        
                          <Form.Field
                            required={{message: "Wall type is required"}}
                            name="damage_walls_type"
                            value={this.state.data.damage_walls_type}
                            control={<React.Fragment>
                              <Padding><Checkbox radio type="circle" name="damage_walls_type" value="gabion" label="Gabion" checked={this.state.data.damage_walls_type == 'gabion'} onChange={() => this.props.onChange({...this.state.data, damage_walls_type: 'gabion'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="damage_walls_type" value="masonry" label="Masonry" checked={this.state.data.damage_walls_type == 'masonry'} onChange={() => this.props.onChange({...this.state.data, damage_walls_type: 'masonry'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="damage_walls_type" value="concrete" label="Reinforced concrete" checked={this.state.data.damage_walls_type == 'concrete'} onChange={() => this.props.onChange({...this.state.data, damage_walls_type: 'concrete'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="damage_walls_type" value="other" label="Other" checked={this.state.data.damage_walls_type == 'other'} onChange={() => this.props.onChange({...this.state.data, damage_walls_type: 'other'}, true)}/></Padding>
                            </React.Fragment>}
                            hint={<React.Fragment>Select the type of retaining wall</React.Fragment>}/>
                        </React.Fragment>}
                      </Block>

                      <Block title="Culverts">
                        <Form.Field 
                          name="damage_culverts" forceupdate value={this.state.data.damage_culverts}
                          control={<Checkbox label="Did the landslide cause any damage to culverts?" checked={this.state.data.damage_culverts}/>}/>
                          {this.state.data.damage_culverts == true && <React.Fragment>
                            <Form.Field 
                              required={{message: "Number of culverts is required"}}
                              isInt={{message: "Number of culverts must be a whole number"}}
                              min={{value: 1, message: "Number of culverts must be at least one"}}
                              label="Culverts"
                              name="damage_culverts_number" 
                              value={this.state.data.damage_culverts_number}
                              control={(<Input type="text" placeholder="Number" fluid/>)}
                              hint={(<React.Fragment>Number of culverts, e.g. <code>3</code></React.Fragment>)}/>
                            <Form.Field
                              required={{message: "Culvert type is required"}}
                              name="damage_culverts_type"
                              value={this.state.data.damage_culverts_type}
                              control={<React.Fragment>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_type" value="pipe" label="Pipe" checked={this.state.data.damage_culverts_type == 'pipe'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_type: 'pipe'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_type" value="box" label="Box" checked={this.state.data.damage_culverts_type == 'box'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_type: 'box'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_type" value="arch" label="Pipe arch" checked={this.state.data.damage_culverts_type == 'arch'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_type: 'arch'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_type" value="bridge" label="Bridge" checked={this.state.data.damage_culverts_type == 'bridge'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_type: 'bridge'}, true)}/></Padding>
                              </React.Fragment>}
                              hint={<React.Fragment>Select the type of culvert that was damaged</React.Fragment>}/>
                            <Form.Field
                              required={{message: "Culvert material is required"}}
                              name="damage_culverts_material"
                              value={this.state.data.damage_culverts_material}
                              control={<React.Fragment>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_material" value="concrete" label="Concrete" checked={this.state.data.damage_culverts_material == 'concrete'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_material: 'concrete'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_material" value="steel" label="Steel" checked={this.state.data.damage_culverts_material == 'steel'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_material: 'steel'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_material" value="plastic" label="Plastic" checked={this.state.data.damage_culverts_material == 'plastic'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_material: 'plastic'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_material" value="composite" label="Composite" checked={this.state.data.damage_culverts_material == 'composite'} onChange={() => this.props.onChange({...this.state.data, damage_culverts_material: 'composite'}, true)}/></Padding>
                              </React.Fragment>}
                              hint={<React.Fragment>Select the material of the culvert that was damaged</React.Fragment>}/>
                            <Form.Field
                              required={{message: "Culvert size is required"}}
                              name="damage_culverts_size"
                              value={this.state.data.damage_culverts_size}
                              control={<React.Fragment>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_size" value={300} label="300mm" checked={this.state.data.damage_culverts_size == 300} onChange={() => this.props.onChange({...this.state.data, damage_culverts_size: 300}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_size" value={450} label="450mm" checked={this.state.data.damage_culverts_size == 450} onChange={() => this.props.onChange({...this.state.data, damage_culverts_size: 450}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_size" value={600} label="600mm" checked={this.state.data.damage_culverts_size == 600} onChange={() => this.props.onChange({...this.state.data, damage_culverts_size: 600}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_size" value={900} label="900mm" checked={this.state.data.damage_culverts_size == 950} onChange={() => this.props.onChange({...this.state.data, damage_culverts_size: 900}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_size" value={1200} label="1200mm" checked={this.state.data.damage_culverts_size == 950} onChange={() => this.props.onChange({...this.state.data, damage_culverts_size: 1200}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_size" value={1800} label="1800mm" checked={this.state.data.damage_culverts_size == 950} onChange={() => this.props.onChange({...this.state.data, damage_culverts_size: 1800}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_culverts_size" value={2100} label="2100mm" checked={this.state.data.damage_culverts_size == 950} onChange={() => this.props.onChange({...this.state.data, damage_culverts_size: 2100}, true)}/></Padding>
                              </React.Fragment>}
                              hint={<React.Fragment>Select the size of the culvert that was damaged</React.Fragment>}/>
                          </React.Fragment>}                    
                      </Block>

                      <Block title="Bridges">
                        <Form.Field 
                          name="damage_bridges" forceupdate value={this.state.data.damage_bridges}
                          control={<Checkbox label="Did the landslide cause any damage to a bridge?" checked={this.state.data.damage_bridges}/>}/>
                          {this.state.data.damage_bridges == true && <React.Fragment>
                            <Form.Field 
                              required={{message: "Bridge span is required"}}
                              isFloat={{message: "Bridge span must be a number"}}
                              min={{value: 0, message: "Bridge span must be a positive number"}}
                              label="Bridge span"
                              name="damage_bridges_span" 
                              value={this.state.data.damage_bridges_span}
                              control={(<Input type="text" placeholder="Span" fluid><Label attached="right" pointing basic>m</Label></Input>)}
                              hint={(<React.Fragment>Damaged bridge span in meteres, e.g. <code>5.8</code></React.Fragment>)}/>
                            <Form.Field
                              required={{message: "Bridge type is required"}}
                              name="damage_bridges_type"
                              value={this.state.data.damage_bridges_type}
                              control={<React.Fragment>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_type" value="beam" label="Beam" checked={this.state.data.damage_bridges_type == 'beam'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_type: 'beam'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_type" value="arch" label="Arch" checked={this.state.data.damage_bridges_type == 'arch'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_type: 'arch'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_type" value="cantilever" label="Cantilever" checked={this.state.data.damage_bridges_type == 'cantilever'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_type: 'cantilever'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_type" value="suspension" label="Suspension" checked={this.state.data.damage_bridges_type == 'suspension'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_type: 'suspension'}, true)}/></Padding>
                              </React.Fragment>}
                              hint={<React.Fragment>Select the type of bridge that was damaged</React.Fragment>}/>
                            <Form.Field
                              required={{message: "Bridge material is required"}}
                              name="damage_bridges_material"
                              value={this.state.data.damage_bridges_material}
                              control={<React.Fragment>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_material" value="wood" label="Wood" checked={this.state.data.damage_bridges_material == 'wood'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_material: 'wood'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_material" value="steel" label="Steel" checked={this.state.data.damage_bridges_material == 'steel'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_material: 'steel'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_material" value="concrete" label="Concrete" checked={this.state.data.damage_bridges_material == 'concrete'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_material: 'concrete'}, true)}/></Padding>
                                <Padding><Checkbox radio type="circle" name="damage_bridges_material" value="composite" label="Composite" checked={this.state.data.damage_bridges_material == 'composite'} onChange={() => this.props.onChange({...this.state.data, damage_bridges_material: 'composite'}, true)}/></Padding>
                              </React.Fragment>}
                              hint={<React.Fragment>Select the material of the bridge that was damaged</React.Fragment>}/>
                          </React.Fragment>}                    
                      </Block>    

                      <Block title="Pylons">
                        <Form.Field 
                          name="damage_pylons" forceupdate value={this.state.data.damage_pylons}
                          control={<Checkbox label="Did the landslide cause any damage to pylons?" checked={this.state.data.damage_pylons}/>}/>
                          {this.state.data.damage_pylons == true && <React.Fragment>
                            <Form.Field
                            required={{message: "Pylon type is required"}}
                            name="damage_pylons_type"
                            value={this.state.data.damage_pylons_type}
                            control={<React.Fragment>
                              <Padding><Checkbox radio type="circle" name="damage_pylons_type" value="wood" label="Wood" checked={this.state.data.damage_pylons_type == 'wood'} onChange={() => this.props.onChange({...this.state.data, damage_pylons_type: 'wood'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="damage_pylons_type" value="steel" label="Steel" checked={this.state.data.damage_pylons_type == 'steel'} onChange={() => this.props.onChange({...this.state.data, damage_pylons_type: 'steel'}, true)}/></Padding>
                            </React.Fragment>}
                            hint={<React.Fragment>Select the type of pylons that were damaged</React.Fragment>}/>
                          </React.Fragment>}
                      </Block>                                   

                      <Block title="Cables">
                        <Form.Field 
                          name="damage_cables" forceupdate value={this.state.data.damage_cables}
                          control={<Checkbox label="Did the landslide cause any damage to cables?" checked={this.state.data.damage_cables}/>}/>
                          {this.state.data.damage_cables == true && <React.Fragment>
                            <Question>Please indicate which types of cables were damaged:</Question>
                            <Form.Field forceupdate name="damage_cables_power" value={this.state.data.damage_cables_power} 
                              control={<Checkbox label="Power" checked={this.state.data.damage_cables_power}/>}/>
                            <Form.Field forceupdate name="damage_cables_telephone" value={this.state.data.damage_cables_telephone} 
                              control={<Checkbox label="Telephone Works" checked={this.state.data.damage_cables_telephone}/>}/>
                            <Form.Field forceupdate name="damage_cables_fibre" value={this.state.data.damage_cables_fibre} 
                              control={<Checkbox label="Fibre-optic" checked={this.state.data.damage_cables_fibre}/>}/>
                            <Form.Field forceupdate name="damage_cables_other" value={this.state.data.damage_cables_other} 
                              control={<Checkbox label="Other cable type" checked={this.state.data.damage_cables_other}/>}/>
                            {this.state.data.damage_cables_other && <Form.Field 
                              required={{message: "Cable type is required"}}
                              name="damage_cables_other_description" 
                              value={this.state.data.damage_cables_other_description}
                              control={(<Input type="text" placeholder="Cable type" fluid/>)}
                              hint={(<React.Fragment>Describe the cable type that was damaged</React.Fragment>)}/>}
                          </React.Fragment>}
                      </Block>                      
                    </div>
                    <div>

                      <Block title="Buildings">
                        <Form.Field 
                          name="damage_buildings" forceupdate value={this.state.data.damage_buildings}
                          control={<Checkbox label="Did the landslide cause any damage to buildings?" checked={this.state.data.damage_buildings}/>}/>
                        {this.state.data.damage_buildings == true && <React.Fragment>
                          <Form.Field 
                            required={{message: "Number of unoccupied buildings is required"}}
                            isInt={{message: "Number of unoccupied buildings must be a number"}}
                            min={{value: 0, message: "Number of unoccupied buildings must be positive"}}
                            label="Unoccupied buildings"
                            name="damage_buildings_unoccupied" 
                            value={this.state.data.damage_buildings_unoccupied}
                            control={(<Input type="text" placeholder="Unoccupied buildings" fluid/>)}
                            hint={(<React.Fragment>Number of unoccupied buildings</React.Fragment>)}/>
                          <Form.Field 
                            required={{message: "Number of occupied buildings is required"}}
                            isInt={{message: "Number of occupied buildings must be a number"}}
                            min={{value: 0, message: "Number of occupied buildings must be positive"}}
                            label="Occupied buildings"
                            name="damage_buildings_occupied" 
                            value={this.state.data.damage_buildings_occupied}
                            control={(<Input type="text" placeholder="Occupied buildings" fluid/>)}
                            hint={(<React.Fragment>Number of occupied buildings</React.Fragment>)}/>                        
                          <Form.Field 
                            required={{message: "Number of commercial buildings is required"}}
                            isInt={{message: "Number of commercial buildings must be a number"}}
                            min={{value: 0, message: "Number of commercial buildings must be positive"}}
                            label="Commercial buildings"
                            name="damage_buildings_commercial" 
                            value={this.state.data.damage_buildings_commercial}
                            control={(<Input type="text" placeholder="Commercial buildings" fluid/>)}
                            hint={(<React.Fragment>Number of commercial buildings</React.Fragment>)}/>
                          <Form.Field
                            required={{message: "Damage category is required"}}
                            name="damage_buildings_category"
                            value={this.state.data.damage_buildings_category}
                            control={<React.Fragment>
                              <Padding><Checkbox radio type="circle" name="damage_buildings_category" value="total" label="Total destruction of property" checked={this.state.data.damage_buildings_category == 'total'} onChange={() => this.props.onChange({...this.state.data, damage_buildings_category: 'total'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="damage_buildings_category" value="partial" label="Partial destruction of property" checked={this.state.data.damage_buildings_category == 'partial'} onChange={() => this.props.onChange({...this.state.data, damage_buildings_category: 'partial'}, true)}/></Padding>
                              <Padding><Checkbox radio type="circle" name="damage_buildings_category" value="minimal" label="Minimal destruction of property" checked={this.state.data.damage_buildings_category == 'minimal'} onChange={() => this.props.onChange({...this.state.data, damage_buildings_category: 'minimal'}, true)}/></Padding>
                            </React.Fragment>}
                            hint={<React.Fragment>Select the buildings damage category</React.Fragment>}/>                            
                        </React.Fragment>}
                      </Block>

                      <Block title="Vehicular accidents">
                        <Form.Field 
                          name="damage_vehicular_accidents" forceupdate value={this.state.data.damage_vehicular_accidents}
                          control={<Checkbox label="Were there any vehicular accidents caused by the landslide?" checked={this.state.data.damage_vehicular_accidents}/>}/>
                        {this.state.data.damage_vehicular_accidents == true && <React.Fragment>
                          <Form.Field 
                            required={{message: "Number of vehicles is required"}}
                            isInt={{message: "Number of vehicles must be a number"}}
                            min={{value: 1, message: "Number of vehicles must be at least one"}}
                            label="Number of vehicles involved"
                            name="damage_vehicular_accidents_number" 
                            value={this.state.data.damage_vehicular_accidents_number}
                            control={(<Input type="text" placeholder="Vehicles" fluid/>)}
                            hint={(<React.Fragment>Number of vehicles involved in accidents</React.Fragment>)}/>
                        </React.Fragment>}
                      </Block>

                      <Block title="Injuries">
                        <Form.Field 
                          name="damage_injuries" forceupdate value={this.state.data.damage_injuries}
                          control={<Checkbox label="Were there any injuries caused by the landslide?" checked={this.state.data.damage_injuries}/>}/>
                        {this.state.data.damage_injuries == true && <React.Fragment>
                          <Form.Field 
                            required={{message: "Number of people is required"}}
                            isInt={{message: "Number of people must be a number"}}
                            min={{value: 1, message: "Number of people must be at least one"}}
                            label="Number of people injured"
                            name="damage_injuries_number" 
                            value={this.state.data.damage_injuries_number}
                            control={(<Input type="text" placeholder="People" fluid/>)}
                            hint={(<React.Fragment>Number of people injured in the landslide</React.Fragment>)}/>
                        <Form.Field 
                          required={{message: "Description of injuries is required"}}
                          name="damage_injuries_description" 
                          value={this.state.data.damage_injuries_description}
                          control={(<MarkdownTextarea rows={10} fluid/>)}
                          hint={(<React.Fragment>Describe the injuries</React.Fragment>)}/>
                        </React.Fragment>}
                      </Block>                  

                      <Block title="Fatalities">
                        <Form.Field 
                          name="damage_fatalities" forceupdate value={this.state.data.damage_fatalities}
                          control={<Checkbox label="Did the landslide cause any fatalities?" checked={this.state.data.damage_fatalities}/>}/>
                        {this.state.data.damage_fatalities == true && <React.Fragment>
                          <Form.Field 
                            required={{message: "Number of fatalities is required"}}
                            isInt={{message: "Number of fatalities must be a number"}}
                            min={{value: 1, message: "Number of fatalities must be at least one"}}
                            label="Number of fatalities"
                            name="damage_fatalities_number" 
                            value={this.state.data.damage_fatalities_number}
                            control={(<Input type="text" placeholder="Fatalities" fluid/>)}
                            hint={(<React.Fragment>Number of fatalities caused by the landslide</React.Fragment>)}/>
                        </React.Fragment>}
                      </Block>        

                      <Block title="Other damage">
                        <Form.Field 
                          name="damage_other_description" 
                          value={this.state.data.damage_other_description}
                          control={(<MarkdownTextarea rows={10} fluid/>)}
                          hint={(<React.Fragment>Describe any other damage caused by the landslide</React.Fragment>)}/>
                      </Block>                          

                    </div>
                  </Flex.Columns>
                </Section>
              </StretchInner>
            </Stretch>

          </Tabs.Pane>           

        </Tabs>
      </Form>
    )
  }
}

const Padding = styled('div')`
  padding: 4px 0 4px 0;
`

const Question = styled('div')`
  font-style: italic;
  color: #888;
  margin-top: 4px;
  margin-bottom: 2px;
`

const Stretch = styled('div')`
  position: relative;
  height: 100%;
  margin-top: -14px;
`

const StretchInner = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -14px;
  overflow-y: scroll;
`

export { LandslideForm };
