import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

import { Section, Viewer, Block, NA } from '../../modules';
import { SingleMarkerMap } from '../../map';
import { Issue, IssueFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Flex } from '@independent-software/typeui/controls/Flex';
import { Header } from '@independent-software/typeui/controls/Header';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Markdown } from '@independent-software/typeui/formatters/Markdown';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Table } from '@independent-software/typeui/controls/Table';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Button } from '@independent-software/typeui/controls/Button';
import { CIRCLE } from '../../map/Symbol';

class ViewIssue extends React.Component<IAuthProps & RouteComponentProps<{id: string}>, {}> {
  
  private canEdit = (landslide: Issue): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_issues');
  }

  private handleEdit = () => {
    this.props.history.push(`/issues/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Issue deleted");
    this.props.history.goBack();    
  }

  private handleRoadClick = (id: number) => {
    this.props.history.push(`/roads/${id}`);
  }

  private handleStationClick = (id: number) => {
    this.props.history.push(`/stations/${id}`);
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Issue> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={IssueFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(item: Issue) => 
          <React.Fragment>
            <Section dark padded>
              <Header size="h3">
                {item.title}
              </Header>
              {item.road &&
                <div style={{float: 'right'}}>
                  <Button secondary size='small' onClick={() => this.handleRoadClick(item.road.id)}>View road</Button>
                </div>}
              {item.station && p.auth && p.auth.hasRight('can_view_stations') &&
                <div style={{float: 'right'}}>
                  <Button secondary size='small' onClick={() => this.handleStationClick(item.station.id)}>View station</Button>
                </div>}
              {item.road &&
              <Header size="h3">
                <Icon title="road" url="sprites.svg#road"/> {item.road.linkid}
                {item.station && <React.Fragment>&nbsp;&nbsp;<Icon title="station" url="sprites.svg#hard-hat"/> {item.station.name}</React.Fragment>}
              </Header>}
            </Section>
            <Tabs underlined>
              <Tabs.Pane label="General">
                <Stretch>
                  <StretchInner>
                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Type">
                            {!item.type && "Unknown type"}
                            {item.type == 'inadequate_width' && <React.Fragment>UNRA inability to manage roadside slopes due to lack of or limited road reserve width</React.Fragment>}
                            {item.type == 'adverse_land_user' && <React.Fragment>Land management practices outside the road reserve impacting road reserve maintenance</React.Fragment>}
                            {item.type == 'unauthorized_use' && <React.Fragment>Unauthorized occupation and/or commercial use of the road reserve</React.Fragment>}
                          </Block>
                        </div>
                        <div>
                          <Block title="Description">
                            <Markdown source={item.description}/>
                          </Block>
                        </div>
                        
                      </Flex.Columns>
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

              <Tabs.Pane label="Location">
                <Stretch>
                  
                  <StretchInner>

                  <SingleMarkerMap 
                    auth={p.auth} 
                    lat={parseFloat(item.latitude_start.toString())} 
                    lng={parseFloat(item.longitude_start.toString())} 
                    lat2={parseFloat(item.latitude_end.toString())}
                    lng2={parseFloat(item.longitude_end.toString())}
                    symbol={CIRCLE}/>

                    <Section padded>

                      <Flex.Columns count={2}>
                        <div>
                          <Block title="Road">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Road code</td>
                                  <td style={{textAlign: 'right'}}>{item.road ? item.road.road : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>Road link</td>
                                  <td style={{textAlign: 'right'}}>{item.road ? item.road.linkid : <NA/>}</td>
                                </tr>
                                <tr>
                                  <td>Road link name</td>
                                  <td style={{textAlign: 'right'}}>{item.road ? item.road.link_name : <NA/>}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>
                        </div>
                        <div>
                          <Block title="Location start">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Chainage</td>
                                  <td style={{textAlign: 'right'}}>{item.chainage_start}</td>
                                </tr>
                                <tr>
                                  <td>Latitude</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.latitude_start} decimals={6}/></td>
                                </tr>
                                <tr>
                                  <td>Longitude</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.longitude_start} decimals={6}/></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>
                          <Block title="Location end">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Chainage</td>
                                  <td style={{textAlign: 'right'}}>{item.chainage_end}</td>
                                </tr>
                                <tr>
                                  <td>Latitude</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.latitude_end} decimals={6}/></td>
                                </tr>
                                <tr>
                                  <td>Longitude</td>
                                  <td style={{textAlign: 'right'}}><Number value={item.longitude_end} decimals={6}/></td>
                                </tr>
                              </tbody>
                            </Table>
                          </Block>
                        </div>
                        
                      </Flex.Columns>                
                    </Section>
                  </StretchInner>
                </Stretch>
              </Tabs.Pane>

            </Tabs>

          </React.Fragment>}>
      </Viewer>
    );
  }
}

const Question = styled('div')`
  font-style: italic;
  color: #888;
  margin-bottom: 5px;
`

const Stretch = styled('div')`
  position: relative;
  height: 100%;
  margin-top: -14px;
`

const StretchInner = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -14px;
  overflow-y: scroll;
`

export { ViewIssue };
