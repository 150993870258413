import * as React from 'react';
import { Container, Content, BottomBar, Section, HelpPane } from '../../modules';
import { LandslideForm } from './LandslideForm';
import { LandslideFactory, Landslide } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Header } from '@independent-software/typeui/controls/Header';

type TStep = 'ready' | 'saving' | 'saveError';

interface IState {
  landslide: Landslide;
  isValid: boolean;
  step: TStep;
  error: any;
  dirty?: boolean;
  showHelp: boolean;
}

class AddLandslide extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  private landslide: Landslide;

  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.landslide = LandslideFactory.create();
    this.state = {
      step: 'ready',
      isValid: false,
      error: null,
      landslide: this.landslide,
      showHelp: false
    };
  }

  handleChange = (landslide: Landslide, forceupdate: boolean) => {
    this.landslide = Object.assign(this.landslide, landslide);
    if(forceupdate) { 
      this.setState({ landslide: this.landslide });  
    }
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  handleSubmit = () => {
    this.setState({ landslide: this.landslide, dirty: true });
    if(!this.state.isValid) return;    

    this.setState({error: null, step: 'saving'});
    this.landslide.$save(this.props.auth)
      .then(res => {
        ToastService.toast("Landslide created");
        let id = res.data;
        this.props.history.push(`/landslides/${id}`);
      })
      .catch(error => {
        this.setState({
          step: 'saveError',
          error: error
        })
      });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {this.state.step == 'saving' && <Loader></Loader>}
        <React.Fragment>
          <Content>
            <LandslideForm auth={p.auth} dirty={this.state.dirty} data={this.state.landslide} onChange={this.handleChange} onValidate={this.handleValidate}/>
          </Content>
          <BottomBar>
            <Button primary onClick={this.handleSubmit}><Icon name="save"/> Save</Button>
            <Button secondary icon onClick={() => this.setState({showHelp: true})}><Icon url="sprites.svg#help"/></Button>
          </BottomBar>
        </React.Fragment>
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave} onRetry={this.handleSubmit}/>
        <HelpPane title="Add landslide help" open={this.state.showHelp} onClose={() => this.setState({showHelp: false})}>
          <p>This form is used to add a landslide to the database.</p>
          <Header size="h3">What's here?</Header>
          <p>
            This form has fields for general data on the landslide, as well as for data on location, origin, impact,
            land use, reinstatement and damage. Use the <b>tabs</b> at the top of the screen to navigate between
            different parts of the form.
          </p>
          <Header size="h3">Required data</Header>
          <p>
            While some form fields are optional, most fields are <b>mandatory</b>. These fields are marked with a
            red asterisk <span style={{color:'red'}}>*</span>. You can only save a landslide once
            you have completed all required fields.
          </p>
          <Header size="h3">Setting the landslide location</Header>
          <p>
            This form offers a map to help you set the landslide location. You can either type in the latitude
            and longitude in the appropriate fields, and the map will place a marker at that 
            location, <i>or</i> you can click the map at the right location and the fields will be filled out for you. 
            For convenience, the map offers road, station and susceptibility overlays that you can toggle on or off.
          </p>
          <Header size="h3">Saving data</Header>
          <p>
            When you have completed the form, click the <b>Save</b> button to write the information
            to the database. If you have forgotten to fill out any required fields, the form will
            refuse to save. The missing fields will be highlighted in red. If you can't see the
            missing field, do not forget to look at the other tabs.
          </p>
        </HelpPane>        

      </Container>
    );
  }
}

export { AddLandslide };
