import { HelpPane } from './HelpPane';
export { HelpPane };

/** 
 * This index.ts file exists so that you can do
 *   
 *     import { HelpPane } from '../modules/HelpPane/';
 * 
 * and the required HelpPane.tsx is automatically imported.
 */
