import * as React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from './config/Routes';
import { Auth } from './services/Auth';
import { Screen } from './Screen'; 
import { Public } from './Public';
import { AccessDenied } from './components/AccessDenied/AccessDenied';
import { ToastContainer } from '@independent-software/typeui/controls/Toast';

interface IAppContainerState {
  // Auth data (or null)
  auth: Auth;
}

class AppContainer extends React.Component<{}, IAppContainerState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      auth: Auth.restore()
    };
  }

  handleSignin = (auth: Auth) => {
    this.setState({ auth: auth });
  }

  handleSignout = () => {
    this.state.auth.logout();
    this.setState({ auth: null });
  }

  getPublicRoutes = () => {
    // Go through list of routes (only routes that do NOT require authentication):
    return Object.keys(routes).filter((key) => routes[key].right === false).map((key, index) => {
      let route = routes[key]; 

      return <Route 
        key={index} 
        exact 
        path={route.path} 
        render={(props) => {
          // Instantiate component:
          let component = React.createElement(route.component, {...props, onSignin: this.handleSignin, auth: this.state.auth});
          // Wrap component in Public screen:
          return (
            <Public {...props}>{component}</Public>
          );
        }}
        />

    });
  }

  getAuthRoutes = () => {
    // Go through list of routes (only routes that require authentication)
    return Object.keys(routes).filter((key) => routes[key].right !== false).map((key, index) => {
      let route = routes[key]; 

      return <Route 
        key={index} 
        exact 
        path={route.path} 
        render={(props) => {
          
          // Determine if the user has access:
          let isDenied = !this.state.auth.hasRight(route.right);

          // Instantiate component:
          let component = null;
          if(isDenied) {
            component = <AccessDenied/>;
          } else {
            component = React.createElement(route.component, {...props, auth: this.state.auth});              
          }

          // Wrap component in <Screen>:
          return ( 
            <Screen {...props} 
              title={route.title}
              auth={this.state.auth} 
              onSignout={this.handleSignout}>
              {component}
            </Screen> 
          ) 
        }}
        />
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer maxToasts={5}/>
        <Router>
          <Switch>
            {this.state.auth ? this.getAuthRoutes() : this.getPublicRoutes()}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export { AppContainer };