import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface IProps {
  className?: string;
}

class NABase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        &mdash;
      </div>
    )
  }
}

const NA = styled(NABase)`
  color: #888;
`

export { NA };