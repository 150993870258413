import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Topbar, Sidebar } from './modules/';
import { Window } from './Window';
import { Route, Switch, RouteComponentProps } from 'react-router';
import { Auth } from './services/Auth';
import { routes, menu } from './config/Routes';
import { LIGHT, DARK } from './config/Colors';

interface IScreenProps {
  className?: string;
  children?: React.ReactNode;
}

class PublicBase extends React.Component<IScreenProps & RouteComponentProps, {}> {

  constructor(props: IScreenProps & RouteComponentProps) {
    super(props);
  }

  render() {
    let p = this.props;

    return (
      <div className={p.className}>
        <Road><use xlinkHref={"sprites.svg#road-bg"}></use></Road>
        <Content>
          <Box>
            <Logo><use xlinkHref={"sprites.svg#logo"}></use></Logo>
            {p.children}
          </Box>
        </Content>
      </div>
    );
  }
}

const Content = styled('div')`
  position: absolute;
  width: 400px;
  left: 50%;
  margin-left: -200px;
  padding-top: 20vh;
`

const Logo = styled('svg')`
  width: 350px;
  height: 100px;
  margin-bottom: 40px;
`

const Box = styled('div')`
  background: #333;
  padding: 25px 25px 25px 25px;
  border-radius: 4px;
`

const Road = styled('svg')`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

const Public = styled(PublicBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: ${DARK};
`

export { Public };
