import * as React from 'react';
import { Road, RoadFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Number } from '@independent-software/typeui/formatters/Number';

interface IListRoadsProps extends IListProps {
  /**
   * Event is fired when a road is clicked.
   */
  onClick?: (road:Road) => void;
}

interface IListRoadsState extends IListState<Road> {
}

class ListRoads extends List<Road, IListRoadsProps, IListRoadsState> {
  constructor(props: IAuthProps & IListRoadsProps) {
    super(props, RoadFactory, 'linkid', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };
  }

  componentDidMount() {
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeClass = (value: string) => {
    this.setFilter('class', 'eq', value);
  }  

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search records">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="Filter by class">
          <Dropdown name="class" fluid clearable value={this.getFilter('class', 'eq')} placeholder="Class" 
            data={['A', 'B', 'C']}
            label={(c) => c}
            onChange={this.handleChangeClass}>
            <Dropdown.Column>{(c) => c}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon={{name:'tools', title: 'Filter', inverted: true, cornered: true, bordered: true}} width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={2} label="Road" order="road" dir="asc">{(item:Road) => item.road}</DataTable.Column>
          <DataTable.Column weight={2} label="Link" order="linkid" dir="asc">{(item:Road) => item.linkid}</DataTable.Column>
          <DataTable.Column weight={1} label="Class" order="class" dir="asc">{(item:Road) => item.class}</DataTable.Column>
          <DataTable.Column weight={1} label="Surface" order="surface_cl" dir="asc">{(item:Road) => item.surface_cl}</DataTable.Column>
          <DataTable.Column weight={1} label="Length (km)" order="l_km" dir="asc" align="right">{(item:Road) => <Number value={item.l_km} decimals={2}/>}</DataTable.Column>
          <DataTable.Column weight={1} label="Landslides" order="landslides_count" dir="desc" align="right">{(item:Road) => <Number value={item.landslides_count} decimals={0}/>}</DataTable.Column>
          <DataTable.Column weight={1} label="Issues" order="issues_count" dir="desc" align="right">{(item:Road) => <Number value={item.issues_count} decimals={0}/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListRoads };
