import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Pane } from '@independent-software/typeui/controls/Pane';

interface IHelpPaneProps {
  children?: React.ReactNode;
  title: string;
  open: boolean;
  onClose: () => void;
}

class HelpPane extends React.Component<IHelpPaneProps, {}> {
  render() {
    let p = this.props;
    return (
      <Pane open={p.open} onClose={p.onClose}>
        <HelpHeader>{p.title}</HelpHeader>
        <HelpScrollable>
          {p.children}
          <HelpPadding/>
        </HelpScrollable>
      </Pane>
    );
  }
}

const HelpHeader = styled('div')`
  padding: 15px 15px 15px 15px;
  background: #f0f0f0;
  border-bottom: solid 1px #aaa;
  font-family: 'Roboto Condensed';
  font-size: 28px;
`

const HelpScrollable = styled('div')`
  position: absolute;
  padding: 10px 0 0 0;
  top: 55px;
  bottom: 0;
  left: 15px;
  right: 0;
  padding-right: 10px;
  overflow-y: auto;
`

const HelpPadding = styled('div')`
  height: 25px;
`

export { HelpPane };
